/* eslint-disable no-unused-vars */
import React from 'react';
import TryFreeImg from 'assets/TryFree.jpg';
import logo from 'assets/gardifi.png';
import logoDark from 'assets/logoDark.png';
import { Button, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Radio, RadioGroup, Select, useTheme } from '@mui/material';
import './TryFree.css';
import { useNavigate } from 'react-router';

const TryFree = () => {
  const theme = useTheme();
  const style = {
    backgroundColor: theme.palette.background.default === '#F8F9FA' ? '#F8F9FA' : '#fff'
  };
  const navigate = useNavigate();
  return (
    <div className="TryFree">
      <div className="TryFreeForm">
        <div className="imgLogo">
          <img src={theme.palette.mode === 'light' ? logo : logoDark} alt="gardifi" />
        </div>
        <h1>Try Gardifi free for 14 days</h1>
        <p>We will send you a reminder email before a free trial ends</p>
        <form>
          <FormControl sx={{ m: 1, minWidth: '100%' }}>
            <FormHelperText>Without label</FormHelperText>
            {/* value={age} onChange={handleChange} */}
            <Select displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem value="" sx={{ color: 'text.secondary' }}>
                Select Plan
              </MenuItem>
              <MenuItem value={10}>Basic Plan</MenuItem>
              <MenuItem value={20}>Standard Plan</MenuItem>
              <MenuItem value={30}>Professional Plan</MenuItem>
            </Select>
          </FormControl>
          <ul>
            <li>Unlimited Ads Accounts</li>
            <li>Up to 10,000 Ad Clicks per month</li>
            <li>Cancel risk-free within the first 14 days</li>
          </ul>

          <FormControl component="fieldset" className="BillingForm">
            <h4>Billing cycle</h4>
            <RadioGroup aria-label="Billing " defaultValue="Monthly" name="radio-buttons-group" row>
              <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
              <FormControlLabel value="Annual" control={<Radio />} label="Annual" />
            </RadioGroup>
          </FormControl>
          <div className="BillingFormPrice">
            <h4>Price</h4>
            <span>$49,00</span>
          </div>
          <div className="totalPrice">
            <h2>TOTAL DUE NOW</h2>
            <span>$0</span>
          </div>
          <Button variant="contained" color="success" type="submit" fullWidth onClick={() => navigate('/dashboard/completeInfo')}>
            NEXT
          </Button>
        </form>
        <div className="freeInfo">
          <p className="text-center">You are signed in as sofa@gmail.com</p>
          <p className="text-center">
            To sign out please <a href="#">click here.</a>{' '}
          </p>
        </div>
      </div>
      {theme.palette.mode === 'light' ? (
        <div className={`mainImg2 ${theme.palette.background.default === '#F8F9FA' ? 'mainImg2Contrast' : ''}`} style={style}>
          {/* <img src={TryFreeImg} alt="pay per click" /> */}
        </div>
      ) : (
        <div className="mainImg">{/* <img src={TryFreeImg} alt="pay per click" /> */}</div>
      )}
    </div>
  );
};

export default TryFree;
