/* eslint-disable no-unused-vars */
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, Grid, IconButton, Typography, styled } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useState } from 'react';
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));
const CompetitorDetails = () => {
  //   const company_name = 'swaqny';
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Grid container spacing={2}>
        {/* {adsData?.map((item, index) => ( */}
        <Grid item xs={12} sm={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  R
                </Avatar>
              }
              title="swaqny.com"
              subheader="from 1-1-2024 to 10-2-2024"
            />
            <CardMedia component="img" height="194" image="" alt="Paella dish" />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas
                along with the mussels, if you like.
              </Typography>
              <Button>More Details</Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  R
                </Avatar>
              }
              title="swaqny.com"
              subheader="from 1-1-2024 to 10-2-2024"
            />
            <CardMedia component="img" height="194" image="" alt="Paella dish" />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas
                along with the mussels, if you like.
              </Typography>
              <Button>More Details</Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  R
                </Avatar>
              }
              title="swaqny.com"
              subheader="from 1-1-2024 to 10-2-2024"
            />
            <CardMedia component="img" height="194" image="" alt="Paella dish" />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas
                along with the mussels, if you like.
              </Typography>
              <Button>More Details</Button>
            </CardContent>
          </Card>
        </Grid>
        {/* ))} */}
      </Grid>
    </div>
  );
};

export default CompetitorDetails;
