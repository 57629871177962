/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useRef, useState } from 'react';
import { createContext } from 'react';

import Cookies from 'js-cookie';
import axios from 'axios';
import { notify } from 'utils/notify';
import { useNavigate } from 'react-router';
import { replace } from 'lodash';
// import { notify } from 'src/utils/notify'

export const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState();
  const initialToken = Cookies.get('token');
  const [token, setToken] = useState(initialToken);

  const [userId, setUserId] = useState();
  const [tokenReset, setTokenReset] = useState();

  const handleSetToken = (t) => {
    if (t) {
      setToken(t);
      Cookies.set('token', t, { expires: 30 });
    } else {
      setToken(null);
      Cookies.remove('token');
    }
  };

  const navigate = useNavigate();
  const signUp = async (body, dataLogin) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/register`, body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!res.status) {
        throw new Error(res?.msg);
      }
      login(dataLogin);
      // navigate('/', { replace: true });
      return res.data;
    } catch (error) {
      notify(Object.values(error.response.data.error)[0][0], 'error');
    }
  };

  const login = async (body) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/login`, body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!res.status) {
        throw new Error(res?.msg);
      }
      if (res.status === 200) {
        // console.log(res);
        if (res.data.status === 101) {
          // console.log(res.data);
          setUserInfo(res.data.data);
          handleSetToken(res.data.token);

          navigate('code-verification', { replace: true });
        } else if (res.data.status === 100) {
          // console.log(res.data);
          handleSetToken(null);
          throw Error(res.data.message);
        } else if (res.data.status === 200) {
          // console.log(res.data);
          setUserInfo(res.data.data);
          handleSetToken(res.data.token);
          navigate('/dashboard/default', { replace: true });
        }
      } else {
        handleSetToken(null);
        throw Error(res.data.errors[0]);
      }
      notify('logged in successfully', 'success');
      return res.data;
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const checkLogin = useCallback(async () => {
    if (!token) return;
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/info`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      const data = res.data;
      if (!res) {
        console.error('Response not OK:', res.status, res.statusText);
        handleSetToken(null);
      } else {
        setUserInfo(data.data);
      }
    } catch (error) {
      handleSetToken(null);
    }
  }, [token]);
  const isMount = useRef(false);

  useEffect(() => {
    if (!isMount.current) {
      checkLogin();
      isMount.current = true;
    }
  }, [checkLogin]);

  const logout = () => {
    handleSetToken(null);
  };

  // const [email,setEmail] = useState();

  const updateProfile = async (body) => {
    if (!token) return;
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/update`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token
        }
      });
      const data = res.data;
      if (!res) {
        console.error('Response not OK:', res.status, res.statusText);
        handleSetToken(null);
      } else {
        return data;
      }
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const getDataReset = async (body) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/reset/data`, body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!res.status) {
        throw new Error(res?.msg);
      }
      notify('code send to your mail', 'success');
      setUserId(res?.data?.data?.id);
      navigate('/auth/code-reset', { replace: true });
      return res.data;
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const sendCode = async (body) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/reset`, body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!res.status) {
        throw new Error(res?.msg);
      }
    } catch (error) {
      notify(error.message, 'error');
    }
  };
  const checkCode = async (body) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/reset/check`, body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!res.status) {
        throw new Error(res?.msg);
      }
      if (res.data.status === 200) {
        setTokenReset(res.data.token);
        navigate('/auth/reset-password');
      } else {
        notify('error code');
      }
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const resetPassword = async (body) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/reset/change`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + tokenReset
        }
      });
      if (!res.status) {
        throw new Error(res?.msg);
      }
      navigate('/auth/login', { replace: true });
    } catch (error) {
      notify(error.message, 'error');
    }
  };
  // const getInfo = async()=>{
  //   try {
  //     const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/info`,
  //     { headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: "Bearer " + Cookies.get("token"),
  //     }})
  //     if (!res.status) {
  //       throw new Error(res?.msg);
  //     }
  //     setUserInfo(res.data.data);
  //     // console.log(res.data.data.is_verified);
  //   } catch (error) {
  //     notify(error.message,"error")
  //   }
  // }
  // useEffect(()=>{
  //   getInfo()
  // },[])
  const verifyEmail = async (body) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/verifyEmail`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + Cookies.get('token')
        }
      });
      if (!res.status) {
        throw new Error(res?.msg);
      }
      // console.log(res);
      if (res?.data?.status === 200) {
        console.log(res?.data?.status);
        // checkLogin()
        // navigate('/login');
        navigate('/dashboard/default', { replace: true });
      }
      // if(userInfo.is_verified === 1){
      // }
    } catch (error) {
      notify(error.response.data.error, 'error');
    }
  };

  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AuthContext.Provider
      value={{
        isAuth: !!token,
        signUp,
        login,
        updateProfile,
        getDataReset,
        sendCode,
        resetPassword,
        checkCode,
        verifyEmail,
        userId,
        userInfo,
        tokenReset,
        logout
      }}
    >
      {/* isAuth: !!token, login, logout, userInfo ,token, checkLogin, userId,setUserId,tokenReset,setTokenReset, email,setEmail}}> */}
      {children}
    </AuthContext.Provider>
  );
};
