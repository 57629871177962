import { Avatar, Button, Grid, Stack, Typography } from '@mui/material';
import './Subscription.css';

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import { PopupTransition } from 'components/@extended/Transitions';

import { Trash } from 'iconsax-react';
import CancelPlan from './CancelPlan';

const Subscription = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    setAdd(!add);
    if (customer && !add) setCustomer(null);
  };

  const [customer, setCustomer] = React.useState(null);
  //   const [openCancel, setOpenCancel] = useState(false);
  const [add, setAdd] = React.useState(false);

  //   const handleCloseCancel = () => {
  //     setOpen(!open);
  //   };
  return (
    <>
      <Typography variant="h2" sx={{ mb: 3 }}>
        Manage your subscription
      </Typography>
      <ul style={{ maxWidth: '700px' }} className="subsUl">
        <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{ mb: 3, flex: 1 }}>
            Current plan:
          </Typography>
          <Typography variant="h5" sx={{ mb: 3, flex: 1 }}>
            Basic Plan (5,000 Ad clicks / month)
          </Typography>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{ mb: 3, flex: 1 }}>
            Clicks usage:
          </Typography>
          <Typography variant="h5" sx={{ mb: 3, flex: 1 }}>
            0 clicks
          </Typography>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{ mb: 3, flex: 1 }}>
            Price plan:
          </Typography>
          <Typography variant="h5" sx={{ mb: 3, flex: 1 }}>
            $49,00 per month
          </Typography>
        </li>
      </ul>
      <Typography variant="h6" sx={{ mb: 3 }}>
        After your free trial ends on May 8, 2024, this plan will continue automatically using Mastercard ****1111
      </Typography>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} lg={6}>
          <Stack>
            <Button variant="contained">CHANGE PLAN</Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Stack>
            <Button variant="contained" color="error" onClick={handleClickOpen}>
              CANCEL SUBSCRIPTION
            </Button>
            <Dialog
              open={open}
              onClose={() => handleClose(false)}
              keepMounted
              TransitionComponent={PopupTransition}
              maxWidth="xs"
              aria-labelledby="column-delete-title"
              aria-describedby="column-delete-description"
            >
              <DialogContent sx={{ mt: 2, my: 1 }}>
                <Stack alignItems="center" spacing={3.5}>
                  <Avatar color="error" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
                    <Trash variant="Bold" />
                  </Avatar>
                  <Stack spacing={2}>
                    <Typography variant="h4" align="center">
                      Warning!
                    </Typography>
                    <Typography align="center">
                      After you cancel the subscription your Google Ads account will become susceptible to many different fraud threats.
                      With Gardifi, you can avoid future click fraud attacks.
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Button fullWidth onClick={() => handleClose(false)} color="secondary" variant="outlined">
                      STAY PROTECTED
                    </Button>
                    <Button fullWidth color="error" variant="contained" onClick={handleAdd} autoFocus>
                      ACCEPT RISK
                    </Button>
                    <Dialog
                      maxWidth="sm"
                      TransitionComponent={PopupTransition}
                      keepMounted
                      fullWidth
                      onClose={handleAdd}
                      open={add}
                      sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <CancelPlan
                        customer={customer}
                        onCancel={() => {
                          handleAdd(), handleClose(false);
                        }}
                      />
                    </Dialog>
                  </Stack>
                </Stack>
              </DialogContent>
            </Dialog>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Subscription;
