/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  Dialog,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';

// third-party
import { PatternFormat } from 'react-number-format';
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
  CSVExport,
  HeaderSort,
  IndeterminateCheckbox,
  //   SortingSelect,
  TablePagination,
  TableRowSelection
} from 'components/third-party/ReactTable';

import AddCustomer from 'sections/apps/customer/AddCustomer';
import CustomerView from 'sections/apps/customer/CustomerView';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';

import makeData from 'data/react-table';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';

// assets
import { Add, Book, Calendar, CloudChange, Edit, Eye, Trash, Wallet3 } from 'iconsax-react';
// import { ThemeMode } from 'config';
// import AddAccount from './AddAccount';
// import { ClickContext } from 'components/contextApi/GetClicks';
import { Copy } from 'iconsax-react';

import { IoShieldCheckmarkSharp } from 'react-icons/io5';
import { BiSolidShieldX } from 'react-icons/bi';
import { RiComputerLine } from 'react-icons/ri';
import { GiSmartphone } from 'react-icons/gi';

import { notify } from 'utils/notify';
import EcommerceDataChart from 'sections/widget/chart/EcommerceDataChart';
import EcommerceDataCard from 'components/cards/statistics/EcommerceDataCard';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import DateAdapter from '@mui/x-date-utils/DateAdapter';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getClickDetails } from 'components/rtk/reducers/clickSlice';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  //   const sortBy = { id: 'fatherName', desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    // allColumns,
    visibleColumns,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
    preGlobalFilteredRows,
    setGlobalFilter,
    // setSortBy,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ['avatar', 'email']
        //   , sortBy: [sortBy]
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (matchDownSM) {
      setHiddenColumns(['age', 'contact', 'visits', 'email', 'status', 'avatar']);
    } else {
      setHiddenColumns(['avatar', 'email']);
    }
    // eslint-disable-next-line
  }, [matchDownSM]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChangeStart = (value) => {
    const originalDate = new Date(value);

    // Extract year, month, and day
    const year = originalDate.getFullYear();
    // JavaScript months are zero-based, so add 1 to get the correct month
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');

    // Formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
  };
  const handleChangeEnd = (value) => {
    const originalDate = new Date(value);

    // Extract year, month, and day
    const year = originalDate.getFullYear();
    // JavaScript months are zero-based, so add 1 to get the correct month
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');

    // Formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;
    setEndDate(formattedDate);
  };

  // const { clickDetails, GetClicksDetails } = useContext(ClickContext);
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    if (startDate || endDate) dispatch(getClickDetails({ campaign_id: id, startDate: startDate, endDate: endDate }));
    // GetClicksDetails({ campaign_id: id, startDate: startDate, endDate: endDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <>
      <TableRowSelection selected={Object?.keys(selectedRowIds)?.length} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
            {/* <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} /> */}
            {/* <Button variant="contained" startIcon={<Add />} onClick={handleAdd} size="small">
              Add Account
            </Button> */}
            {/*  */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                onChange={(newValue) => handleChangeStart(newValue?.$d)}
                renderInput={(props) => <TextField {...props} helperText="invalid mask" />}
              />

              <DatePicker
                label="End Date"
                onChange={(newValue) => handleChangeEnd(newValue?.$d)}
                renderInput={(props) => <TextField {...props} helperText="valid mask" />}
              />
            </LocalizationProvider>

            {/*  */}
            <CSVExport
              data={selectedFlatRows?.length > 0 ? selectedFlatRows?.map((d) => d?.original) : data}
              filename={'customer-list.csv'}
            />
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups?.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                {headerGroup.headers?.map((column) => (
                  <TableCell key={column} {...column.getHeaderProps([{ className: column?.className }])}>
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page?.map((row, i) => {
              prepareRow(row);
              const rowProps = row?.getRowProps();

              return (
                <Fragment key={i}>
                  <TableRow
                    {...row?.getRowProps()}
                    onClick={() => {
                      row?.toggleRowSelected();
                    }}
                    sx={{ cursor: 'pointer', bgcolor: row?.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                  >
                    {row?.cells?.map((cell) => (
                      <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                </Fragment>
              );
            })}
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| CUSTOMER - LIST ||============================== //

const ClickDetails = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  //   const data = useMemo(() => makeData(200), []);
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerDeleteId, setCustomerDeleteId] = useState('');
  const [add, setAdd] = useState(false);

  const dispatch = useDispatch();
  const { dataClicks, clickDetails, loading, error } = useSelector((state) => state.click);

  // const { GetClicksDetails, clickDetails } = useContext(ClickContext);
  useEffect(() => {
    dispatch(getClickDetails());
    // GetClicksDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setAdd(!add);
    if (customer && !add) setCustomer(null);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const data = clickDetails?.click;

  const columns = useMemo(
    () => [
      {
        title: 'Row Selection',
        Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'selection',
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        disableSortBy: true
      },
      {
        Header: 'Clicked on',
        accessor: 'created_at',
        Cell: ({ row }) =>
          row && row?.original?.created_at
            ? new Date(row?.original?.created_at).toLocaleDateString() + ',' + new Date(row?.original?.created_at).toLocaleTimeString()
            : ''
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) =>
          !row?.original?.status ? (
            <IoShieldCheckmarkSharp size={30} variant="Bold" color="green" />
          ) : (
            <BiSolidShieldX size={30} variant="Bold" color="red" />
          )
      },
      {
        Header: 'Device',
        accessor: 'deviceType',
        Cell: ({ row }) =>
          row?.original?.deviceType?.toLowerCase() === 'mobile' ? <GiSmartphone size={30} /> : <RiComputerLine size={30} />
      },
      {
        Header: 'IP',
        accessor: 'ip_address',
        Cell: ({ row }) => row?.original?.ip_address?.ip
      },
      {
        Header: 'OS',
        accessor: 'operatingSystem'
      },
      {
        Header: 'Device Model',
        accessor: 'deviceModel'
      },
      {
        Header: 'GCLID',
        accessor: 'gclid',
        Cell: ({ row }) => (
          <Copy
            onClick={() => {
              navigator.clipboard
                .writeText(row?.original?.gclid)
                .then(() => {
                  notify('Copied to clipboard', 'success');
                })
                .catch((err) => {
                  console.error('Failed to copy: ', err);
                });
            }}
          />
        )
        // Cell: ({ row }) => row?.original?.name ?? row?.original?.uuid
        // Cell: ({ value }) => <PatternFormat displayType="text" format="+1 (###) ###-####" mask="_" defaultValue={value} />
      },
      {
        Header: 'Google Ads Account',
        accessor: 'account',
        Cell: ({ row }) => row?.original?.account?.name ?? row?.original?.account?.google_id
      },
      {
        Header: 'Campaign',
        accessor: 'campaign',
        Cell: ({ row }) => row?.original?.campaign?.name ?? row?.original?.campaign?.uuid
      },
      {
        Header: 'Network',
        accessor: 'network',
        Cell: ({ row }) =>
          row?.original?.network === 'g'
            ? 'Google search'
            : row?.original?.network === 's'
            ? 'Search partner'
            : row?.original?.network === 'd'
            ? 'Display Network'
            : row?.original?.network === 'u'
            ? 'Smart Shopping Campaign'
            : row?.original?.network === 'ytv'
            ? 'YouTube videos'
            : row?.original?.network === 'yts'
            ? 'YouTube search'
            : row?.original?.network === 'vp'
            ? 'Google video partners'
            : ''
      },
      {
        Header: 'Keyword',
        accessor: 'keyword'
        // Cell: ({ row }) => row?.original?.campaign?.name ?? row?.original?.campaign?.uuid
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );

  const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={data[Number(row?.id)]} />, [data]);
  return (
    <MainCard content={false}>
      <Grid container rowSpacing={4.5} columnSpacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <EcommerceDataCard
            title="Total Clicks"
            count={clickDetails?.total_clicks}
            iconPrimary={<Wallet3 />}
            percentage={
              <Typography color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {/* <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6% */}
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.primary.main} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <EcommerceDataCard
            title="Successful clicks"
            count={clickDetails?.successful_clicks}
            color="success"
            iconPrimary={<Book color={theme.palette.success.main} />}
            percentage={
              <Typography color="success.main" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {/* <ArrowDown size={16} style={{ transform: 'rotate(-45deg)' }} /> 30.6% */}
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.success.main} />
          </EcommerceDataCard>
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title="Total task"
            count="1468"
            color="success"
            iconPrimary={<Calendar color={theme.palette.success.main} />}
            percentage={
              <Typography color="success.main" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6%
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.success.main} />
          </EcommerceDataCard>
        </Grid> */}
        <Grid item xs={12} sm={6} lg={4}>
          <EcommerceDataCard
            title="Blocked clicks"
            count={clickDetails?.blocked_clicks}
            color="error"
            iconPrimary={<CloudChange color={theme.palette.error.dark} />}
            percentage={
              <Typography color="error.dark" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {/* <ArrowDown size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6% */}
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.error.dark} />
          </EcommerceDataCard>
        </Grid>
      </Grid>
      <ScrollX>
        {data && <ReactTable columns={columns} data={data} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />}
      </ScrollX>
      <AlertCustomerDelete title={customerDeleteId} open={open} handleClose={handleClose} />
      {/* add customer dialog */}
      {/* <Dialog
        maxWidth="sm"
        TransitionComponent={PopupTransition}
        keepMounted
        fullWidth
        onClose={handleAdd}
        open={add}
        sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
        aria-describedby="alert-dialog-slide-description"
      >
        <AddAccount customer={customer} onCancel={handleAdd} />
      </Dialog> */}
    </MainCard>
  );
};

ClickDetails.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  fatherName: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default ClickDetails;
