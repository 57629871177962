/* eslint-disable no-unused-vars */
// // project-imports
// // import applications from './applications';
// // import widget from './widget';
// // import formsTables from './forms-tables';
// // import chartsMap from './charts-map';
// // import support from './support';
// // import pages from './pages';
// import clicks from './clicks';
// import competitors from './competitors';

// // ==============================|| MENU ITEMS ||============================== //

// const menuItems = {
//   items: [clicks, competitors]
//   // pages
//   //  support
//   // chartsMap
//   // , widget, applications, formsTable
// };

// export default menuItems;

// menuItems.js
// MenuItems.js
// MenuItems.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import createClicksObject from './clicks';
import createCompetitorsObject from './competitors';

const MenuItems = () => {
  const { t } = useTranslation();
  const clicks = createClicksObject(t);
  const competitors = createCompetitorsObject(t);


  return {
    items: [
      clicks,
      competitors
      // Add other items here if necessary
    ]
  };
};

export default MenuItems;
