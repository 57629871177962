/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, useEffect } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import About from 'pages/About';
import Privacy from 'pages/Privacy';
import UsagePolicy from 'pages/UsagePolicy';
import Pricing from 'sections/landing/Pricing';
import CoockiePolicy from 'pages/CoockiePolicy';
import AdminRoutes from './AdminRoutes';
import { useTranslation } from 'react-i18next';
import useConfig from 'hooks/useConfig';
import TryFree from 'pages/TryFree/TryFree';
import AuthGuard from 'utils/route-guard/AuthGuard';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const ContactUS = Loadable(lazy(() => import('pages/ContactUs')));
const PagesLanding = Loadable(lazy(() => import('pages/landing')));

export default function ThemeRoutes() {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const location = useLocation();
  const { onChangeLocalization, ...config } = useConfig();

  // console.log(config.i18n,"configo");
  // Determine language from URL
  const determineLanguageFromUrl = (pathname) => {
    if (pathname.startsWith('/ar')) {
      return 'ar';
    } else if (pathname.startsWith('/en')) {
      return 'en';
    }
    return null;
  };

  // Set language and navigate if language prefix found in URL
  const setLanguageFromUrl = () => {
    const langFromUrl = determineLanguageFromUrl(location.pathname);
    if (langFromUrl) {
      onChangeLocalization(langFromUrl);
    } else {
      const storedLang = config.i18n;
      if (storedLang) {
        onChangeLocalization(storedLang);
      } else {
        onChangeLocalization('ar'); // Default to Arabic if no language is stored
      }
    }
  };

  // Call function to set language from URL on component mount
  useEffect(() => {
    setLanguageFromUrl();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  // Route configuration
  // const langPrefix = 'ar';

  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          element: <PagesLanding />
        },
        {
          path: 'about-us',
          element: <About title={'About us'} />
        },
        {
          path: 'privacy-policy',
          element: <Privacy title={'Privacy policy'} />
        },
        {
          path: 'terms-and-conditions',
          element: <UsagePolicy title={'terms and conditions'} />
        },
        {
          path: 'cookie-policy',
          element: <CoockiePolicy title={'Cookie policy'} />
        },
        {
          path: 'pricing',
          element: <Pricing title={t('Pricing')} />
        },
        {
          path: 'contact-us',
          element: <ContactUS />
        }
      ]
    },
    {
      path: '/ar',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/ar',
          element: <PagesLanding />
        },
        {
          path: 'about-us',
          element: <About title={'About us'} />
        },
        {
          path: 'privacy-policy',
          element: <Privacy title={'Privacy policy'} />
        },
        {
          path: 'terms-and-conditions',
          element: <UsagePolicy title={'terms and conditions'} />
        },
        {
          path: 'cookie-policy',
          element: <CoockiePolicy title={'Cookie policy'} />
        },
        {
          path: 'pricing',
          element: <Pricing title={t('Pricing')} />
        },
        {
          path: 'contact-us',
          element: <ContactUS />
        }
      ]
    },
    {
      path: '/en',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/en',
          element: <PagesLanding />
        },
        {
          path: 'about-us',
          element: <About title={'About us'} />
        },
        {
          path: 'privacy-policy',
          element: <Privacy title={'Privacy policy'} />
        },
        {
          path: 'terms-and-conditions',
          element: <UsagePolicy title={'terms and conditions'} />
        },
        {
          path: 'cookie-policy',
          element: <CoockiePolicy title={'Cookie policy'} />
        },
        {
          path: 'pricing',
          element: <Pricing title={t('Pricing')} />
        },
        {
          path: 'contact-us',
          element: <ContactUS />
        }
      ]
    },
    {
      path: '*',
      element: <MaintenanceError />
    },
    {
      path: `/setSubscription`,
      // element: <TryFree />
      element: (
        <AuthGuard>
          <TryFree />
        </AuthGuard>
      )
    },
    {
      path: `/ar/setSubscription`,
      element: <TryFree />
    },
    {
      path: `/en/setSubscription`,
      element: <TryFree />
    },

    MainRoutes('en'),
    MainRoutes('ar'),
    LoginRoutes('en'),
    LoginRoutes('ar'),
    MainRoutes(''),
    LoginRoutes(''),
    AdminRoutes
  ]);
}

// import { lazy } from 'react';
// import { useRoutes } from 'react-router-dom';

// // project-imports
// import CommonLayout from 'layout/CommonLayout';
// import Loadable from 'components/Loadable';
// // import ComponentsRoutes from './ComponentsRoutes';
// import LoginRoutes from './LoginRoutes';
// import MainRoutes from './MainRoutes';
// import About from 'pages/About';
// import Privacy from 'pages/Privacy';
// import UsagePolicy from 'pages/UsagePolicy';
// import Pricing from 'sections/landing/Pricing';
// // import Payment from 'sections/landing/Payment';
// import CoockiePolicy from 'pages/CoockiePolicy';
// import AdminRoutes from './AdminRoutes';
// import { useTranslation } from 'react-i18next';
// const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
// const ContactUS = Loadable(lazy(() => import('pages/ContactUs')));

// // render - landing page
// const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// // ==============================|| ROUTES RENDER ||============================== //

// export default function ThemeRoutes() {
//   const { t } = useTranslation();
//   return useRoutes([
//     {
//       path: '/',
//       element: <CommonLayout layout="landing" />,
//       children: [
//         {
//           path: '/',
//           element: <PagesLanding />
//         },
//         {
//           path: 'about-us',
//           element: <About title={'About us'} />
//         },
//         {
//           path: 'privacy-policy',
//           element: <Privacy title={'Privacy policy'} />
//         },
//         {
//           path: 'terms-and-conditions',
//           element: <UsagePolicy title={'terms and conditions'} />
//         },
//         {
//           path: 'cookie-policy',
//           element: <CoockiePolicy title={'Cookie policy'} />
//         },
//         {
//           path: 'pricing',
//           element: <Pricing title={t('Pricing')} />
//         },
//         // {
//         //   path: 'payment',
//         //   element: <Payment title={'payment'} />
//         // },
//         {
//           path: 'contact-us',
//           element: <ContactUS />
//         }
//       ]
//     },
//     {
//       path: '*',
//       element: <MaintenanceError />
//     },
//     LoginRoutes,
//     // ComponentsRoutes,
//     MainRoutes,
//     AdminRoutes
//   ]);
// }
