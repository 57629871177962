/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';

// third-party
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import {
  CSVExport,
  HeaderSort,
  IndeterminateCheckbox,
  //   SortingSelect,
  TablePagination,
  TableRowSelection
} from 'components/third-party/ReactTable';

import CustomerView from 'sections/apps/customer/CustomerView';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';

import { renderFilterTypes, GlobalFilter } from 'utils/react-table';

// assets
import { Book, Calendar, CloudChange, Wallet3 } from 'iconsax-react';
// import AddAccount from './AddAccount';
// import { CampaignContext } from 'components/contextApi/GetCampaigns';
import EcommerceDataCard from 'components/cards/statistics/EcommerceDataCard';
import EcommerceDataChart from 'sections/widget/chart/EcommerceDataChart';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignDetails } from 'components/rtk/reducers/campaignSlice';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  //   const sortBy = { id: 'fatherName', desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    // allColumns,
    visibleColumns,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
    preGlobalFilteredRows,
    setGlobalFilter,
    // setSortBy,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ['avatar', 'email']
        //   , sortBy: [sortBy]
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (matchDownSM) {
      setHiddenColumns(['age', 'contact', 'visits', 'email', 'status', 'avatar']);
    } else {
      setHiddenColumns(['avatar', 'email']);
    }
    // eslint-disable-next-line
  }, [matchDownSM]);
  const navigate = useNavigate()
  return (
    <>
      <TableRowSelection selected={Object?.keys(selectedRowIds)?.length} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
            {/* <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} /> */}
            {/* <Button variant="contained" startIcon={<Add />} onClick={handleAdd} size="small">
              Add Account
            </Button> */}
            <CSVExport
              data={selectedFlatRows?.length > 0 ? selectedFlatRows?.map((d) => d?.original) : data}
              filename={'customer-list.csv'}
            />
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups?.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                {headerGroup.headers?.map((column) => (
                  <TableCell key={column} {...column.getHeaderProps([{ className: column?.className }])}>
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page?.map((row, i) => {
              prepareRow(row);
              const rowProps = row?.getRowProps();

              return (
                <Fragment key={i}>
                  <TableRow
                    {...row?.getRowProps()}
                    onClick={() => {
                      navigate(`/dashboard/click/${row.original.id}`)
                    }}
                    sx={{ cursor: 'pointer', bgcolor: row?.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                  >
                    {row?.cells?.map((cell) => (
                      <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                </Fragment>
              );
            })}
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| CUSTOMER - LIST ||============================== //

const CampaignsDetails = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  //   const data = useMemo(() => makeData(200), []);
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerDeleteId, setCustomerDeleteId] = useState('');
  const [add, setAdd] = useState(false);

  // const { GetCampaignsDetails, campaignDetails } = useContext(CampaignContext);
  const dispatch = useDispatch();
  const { dataCampaigns, campaignDetails, loading, error } = useSelector((state) => state.campaign);

  const { id } = useParams();
  useEffect(() => {
    // GetCampaignsDetails({ account_id: id });
    dispatch(getCampaignDetails({ account_id: id }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setAdd(!add);
    if (customer && !add) setCustomer(null);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const data = campaignDetails?.campaign;

  const columns = useMemo(
    () => [
      {
        title: 'Row Selection',
        Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'selection',
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        disableSortBy: true
      },
      {
        Header: 'Campaign',
        accessor: 'google_id',
        Cell: ({ row }) => row?.original?.name ?? row?.original?.uuid
        // Cell: ({ value }) => <PatternFormat displayType="text" format="+1 (###) ###-####" mask="_" defaultValue={value} />
      },
      {
        Header: 'Google Ads Account',
        accessor: 'name',
        // className: 'cell-right'
        Cell: ({ row }) => row?.original?.account?.name ?? row?.original?.account?.google_id
      },
      {
        Header: 'Ad Clicks',
        accessor: 'click',
        Cell: ({ row }) => (
          <p title={`Successful clicks: ${row.original.successful_clicks}, Blocked clicks: ${row?.original?.blocked_clicks}`}>
            {row?.original?.click}
          </p>
        )
      },
      {
        Header: 'Blocked IP',
        accessor: 'block_ip'
      },
      {
        Header: 'Saved budget',
        accessor: 'Saved_budget',
        Cell: ({ value }) => {
          return 0;
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );

  const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={data[Number(row.id)]} />, [data]);

  return (
    <MainCard content={false}>
      <Grid container rowSpacing={4.5} columnSpacing={3}>
        <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title="Total Campaigns"
            count={campaignDetails?.count_campaign}
            iconPrimary={<Wallet3 />}
            percentage={
              <Typography color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {/* <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6% */}
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.primary.main} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title="Total Clicks"
            count={campaignDetails?.total_clicks}
            color="info"
            iconPrimary={<Calendar color={theme.palette.info.main} />}
            percentage={
              <Typography color="info.main" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {/* <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6% */}
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.info.main} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title="Successful clicks"
            count={campaignDetails?.successful_clicks}
            color="success"
            iconPrimary={<Book color={theme.palette.success.main} />}
            percentage={
              <Typography color="success.main" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {/* <ArrowDown size={16} style={{ transform: 'rotate(-45deg)' }} /> 30.6% */}
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.success.main} />
          </EcommerceDataCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title="Blocked clicks"
            count={campaignDetails?.blocked_clicks}
            color="error"
            iconPrimary={<CloudChange color={theme.palette.error.dark} />}
            percentage={
              <Typography color="error.dark" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {/* <ArrowDown size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6% */}
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.error.dark} />
          </EcommerceDataCard>
        </Grid>
      </Grid>
      <ScrollX>
        {data && <ReactTable columns={columns} data={data} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />}
      </ScrollX>
      <AlertCustomerDelete title={customerDeleteId} open={open} handleClose={handleClose} />
      {/* add customer dialog */}
    </MainCard>
  );
};

CampaignsDetails.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  fatherName: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default CampaignsDetails;
