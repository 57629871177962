import { lazy } from 'react';

// project-imports
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AdminLayout from 'layout/AdminLayout';
import Accounts from 'pages/AdminPages/Accounts/Users';
import AdminUsers from 'pages/AdminPages/AdminUsers/AdminUsers';
import Roles from 'pages/AdminPages/Roles/Roles';
import EditRole from 'pages/AdminPages/EditRole/EditRole';

// render - dashboard
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));

// render - widget

const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

// render - charts & map
// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/auth1/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/auth1/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/auth1/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/auth1/code-verification')));
const AuthCodeReset = Loadable(lazy(() => import('pages/auth/auth1/code-reset')));

// render - sample page

// const Accounts = Loadable(lazy(() => import('pages/clicksPages/Accounts/Accounts')));
// ==============================|| MAIN ROUTES ||============================== //

const AdminRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'Dashboard',
          children: [
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            }
          ]
        },
        {
          path: 'Admin',
          children: [
            {
              path: 'users',
              element: <Accounts />
            },
            {
              path: 'admin_users',
              element: <AdminUsers />
            },
            {
              path: 'roles',
              element: <Roles />
            },
            {
              path: 'role/:id',
              element: <EditRole />
            },
            {
              path: 'profile',
              element: <AccountProfile />,
              children: [
                {
                  path: '',
                  element: <AccountTabProfile />
                },
                {
                  path: 'personal',
                  element: <AccountTabPersonal />
                },
                {
                  path: 'my-account',
                  element: <AccountTabAccount />
                },
                {
                  path: 'password',
                  element: <AccountTabPassword />
                },
                {
                  path: 'role',
                  element: <AccountTabRole />
                },
                {
                  path: 'settings',
                  element: <AccountTabSettings />
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/auth',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        },
        {
          path: 'code-reset',
          element: <AuthCodeReset />
        }
      ]
    }
  ]
};

export default AdminRoutes;
