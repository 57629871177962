/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
  Button,
  // Chip,
  Dialog,
  // Grid,
  // IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // Tooltip,
  // Typography,
  useMediaQuery
} from '@mui/material';
// third-party
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';
// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { PopupTransition } from 'components/@extended/Transitions';
import { CSVExport, HeaderSort, IndeterminateCheckbox, TablePagination, TableRowSelection } from 'components/third-party/ReactTable';
import CustomerView from 'sections/apps/customer/CustomerView';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';
// assets
import { Add, Book, CloudChange, Eye, Trash, Wallet3 } from 'iconsax-react';
// import AddAccount from './AddUsers';
// import { AccountContext } from 'components/contextApi/GetAccounts';
// import EcommerceDataChart from 'sections/widget/chart/EcommerceDataChart';
// import EcommerceDataCard from 'components/cards/statistics/EcommerceDataCard';
import AddAccount from '../Accounts/AddUsers';
import { useDispatch } from 'react-redux';
import { getAccounts } from 'components/rtk/reducers/accountSlice';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  //   const sortBy = { id: 'fatherName', desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    visibleColumns,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ['avatar', 'email']
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  return (
    <>
      <TableRowSelection selected={Object?.keys(selectedRowIds)?.length} />
      <Stack spacing={3}>
        <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems="center" sx={{ p: 3, pb: 0 }}>
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
            {/* <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} /> */}
            <Button variant="contained" onClick={() => console.log('add role')} size="small">
              Save
            </Button>
            <CSVExport
              data={selectedFlatRows?.length > 0 ? selectedFlatRows?.map((d) => d?.original) : data}
              filename={'customer-list.csv'}
            />
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups?.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                {headerGroup.headers?.map((column) => (
                  <TableCell key={column} {...column.getHeaderProps([{ className: column?.className }])}>
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page?.map((row, i) => {
              prepareRow(row);
              const rowProps = row?.getRowProps();

              return (
                <Fragment key={i}>
                  <TableRow
                    {...row?.getRowProps()}
                    sx={{ cursor: 'pointer', bgcolor: row?.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                  >
                    {row?.cells?.map((cell) => (
                      <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                </Fragment>
              );
            })}
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| CUSTOMER - LIST ||============================== //

const EditRole = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  //   const data = useMemo(() => makeData(200), []);
  const [customerDeleteId, setCustomerDeleteId] = useState('');
  const [customer, setCustomer] = useState(null);
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);

  // const { GetAccounts, dataAccounts } = useContext(AccountContext);
  // useEffect(() => {
  //   GetAccounts();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccounts());
    // GetAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleAdd = () => {
    setAdd(!add);
    if (customer && !add) setCustomer(null);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const data = [{ a: 1 }];

  const columns = useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'Role',
        Cell: ({ row }) => {
          const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
          return <p>user</p>;
        }
      },
      {
        Header: 'view',
        Cell: ({ row }) => {
          const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
          return <Switch defaultChecked />;
        }
      },
      {
        Header: 'Edit',
        Cell: ({ row }) => {
          const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
          return <Switch defaultChecked />;
        }
      },
      {
        Header: 'Delete',
        Cell: ({ row }) => {
          const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
          return <Switch defaultChecked />;
        }
      },
      {
        Header: 'Add',
        Cell: ({ row }) => {
          const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
          return <Switch defaultChecked />;
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );

  const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={data[Number(row.id)]} />, [data]);
  return (
    <MainCard content={false}>
      <ScrollX>
        {data && <ReactTable columns={columns} data={data} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />}
      </ScrollX>
      <AlertCustomerDelete title={customerDeleteId} open={open} handleClose={handleClose} />
      {/* add customer dialog */}
      <Dialog
        maxWidth="sm"
        TransitionComponent={PopupTransition}
        keepMounted
        fullWidth
        onClose={handleAdd}
        open={add}
        sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
        aria-describedby="alert-dialog-slide-description"
      >
        <AddAccount customer={customer} onCancel={handleAdd} />
      </Dialog>
    </MainCard>
  );
};

EditRole.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  fatherName: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default EditRole;
