/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { createContext } from 'react';

import { notify } from 'utils/notify';
import { getRequest, postRequest } from 'utils/fetchMethods';
// postRequest
export const EditorContext = createContext();

// eslint-disable-next-line react/prop-types
export const EditorProvider = ({ children }) => {
  const [dataEditorIds, setDataEditorIds] = useState();
  const [pageData, setPageData] = useState();
  const [text_en, setText_en] = useState();
  const [text_ar, setText_ar] = useState();
  const [name_en, setName_en] = useState();
  const [name_ar, setName_ar] = useState();
  const [meta_en, setMeta_en] = useState();
  const [meta_ar, setMeta_ar] = useState();
  const GetEditorIds = async (params) => {
    try {
      const res = await getRequest(params, `/page-admin/getIds`);
      if (!res) {
        throw new Error(res?.msg);
      }
      setDataEditorIds(res);
    } catch (error) {
      notify(error.message, 'error');
    }
  };
  const GetPageFromId = async (params) => {
    try {
      const res = await getRequest(params, `/page-admin/getPage`);
      if (!res) {
        throw new Error(res?.msg);
      }
      setPageData(res);
    } catch (error) {
      notify(error.message, 'error');
    }
  };
  // console.log(dataAccounts);

  const postPage = async (body) => {
    try {
      const res = await postRequest(body, `/page-admin/update`);
      if (!res) {
        throw new Error(res?.message);
      }
      notify(res.message, 'success');
    } catch (error) {
      console.log(error);
      notify(error.message, 'error');
    }
  };

  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <EditorContext.Provider
      value={{
        text_en,
        setText_en,
        text_ar,
        setText_ar,
        name_en,
        setName_en,
        name_ar,
        setName_ar,
        GetEditorIds,
        GetPageFromId,
        postPage,
        dataEditorIds,
        pageData,
        meta_en, setMeta_en,
        meta_ar, setMeta_ar
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
