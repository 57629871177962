/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, FormControl, MenuItem, TableCell, Tooltip } from '@mui/material';

// third-party
import { getIn } from 'formik';

// project-imports
import AlertProductDelete from 'sections/apps/invoice/AlertProductDelete';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';

// assets
import { Trash } from 'iconsax-react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Select from '@mui/material/Select';
import { AdsContext } from 'components/contextApi/AdsContext';
import { format } from 'date-fns';

// ==============================|| INVOICE - ITEMS ||============================== //

const AddCompItem = ({ country_id, onDeleteItem, onEditItem, index, Blur, errors, touched }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const [open, setOpen] = useState(false);
  const handleModalClose = (status) => {
    setOpen(false);
    if (status) {
      onDeleteItem(index);
      dispatch(
        openSnackbar({
          open: true,
          message: 'Product Deleted successfully',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    }
  };

  const { countries } = useContext(AdsContext);

  const country = `invoice_detail[${index}].country_id`;
  const touchedcountry = getIn(touched, country);
  const errorcountry = getIn(errors, country);
  return (
    <>
      <TableCell>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              name={`invoice_detail.${index}.start_date`}
              id={`invoice_detail.${index}.start_date`}
              value={`invoice_detail.${index}.start_date`}
              onChange={(newValue) => {
                const formattedDate = format(newValue, 'yyyy-MM-dd'); // Format the date string as 'dd-MM-yyyy'
                setFieldValue(`invoice_detail.${index}.start_date`, formattedDate);
              }}
              onBlur={(event) => Blur(event)}
            />
          </LocalizationProvider>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={`invoice_detail.${index}.end_date`}
              name={`invoice_detail.${index}.end_date`}
              onChange={(newValue) => {
                const formattedDate = format(newValue, 'yyyy-MM-dd'); // Format the date string as 'dd-MM-yyyy'
                setFieldValue(`invoice_detail.${index}.end_date`, formattedDate);
              }}
              onBlur={(event) => Blur(event)}
            />
          </LocalizationProvider>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl sx={{ width: '100%' }}>
          <Select
            value={country_id}
            displayEmpty
            name={`invoice_detail.${index}.country_id`}
            onChange={(event) => onEditItem(event)}
            onBlur={(event) => Blur(event)}
            // onChange={handleChange}
            error={Boolean(errors.errorcountry && touched.touchedcountry)}
          >
            <MenuItem disabled value="">
              Select country
            </MenuItem>
            {countries?.map((item) => (
              <MenuItem key={item.id} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                opacity: 0.9
              }
            }
          }}
          title="Remove Item"
        >
          <Button color="error" onClick={() => setOpen(true)}>
            <Trash />
          </Button>
        </Tooltip>
      </TableCell>
      <AlertProductDelete title={name} open={open} handleClose={handleModalClose} />
    </>
  );
};

AddCompItem.propTypes = {
  id: PropTypes.number,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  country_id: PropTypes.string,
  onDeleteItem: PropTypes.func,
  onEditItem: PropTypes.func,
  index: PropTypes.number,
  Blur: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object
};

export default AddCompItem;
