// store/accountSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notify } from 'utils/notify';
import { getRequest, postRequest } from 'utils/fetchMethods';

// Async thunk for fetching accounts
export const getAccounts = createAsyncThunk('account/getAccounts', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/users/google-account`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

// Async thunk for posting an account
export const postAccount = createAsyncThunk('account/postAccount', async (body, { rejectWithValue }) => {
  try {
    const res = await postRequest(body, `/users/google-account/store`);
    if (!res) {
      throw new Error(res?.msg);
    }
    notify(res.message, 'success');
    return res;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

// Slice to manage account state
const accountSlice = createSlice({
  name: 'account',
  initialState: {
    dataAccounts: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.dataAccounts = action.payload;
      })
      .addCase(getAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postAccount.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default accountSlice.reducer;
