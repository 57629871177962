// project-imports
import clicks from './clicks';

// ==============================|| admin ITEMS ||============================== //

const adminItems = {
  items: [clicks]
};

export default adminItems;
