import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto', flexWrap: 'wrap' }}>
      <Typography variant="caption">&copy; Gardifi ♥</Typography>
      <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
        <Link component={RouterLink} to="/" target="_blank" variant="caption" color="textPrimary">
          {t('Home')}
        </Link>
        <Link component={RouterLink} to="/about-us" target="_blank" variant="caption" color="textPrimary">
          {t('About_us')}
        </Link>
        <Link component={RouterLink} to="/contact-us" target="_blank" variant="caption" color="textPrimary">
          {t('Contact_us')}
        </Link>
        <Link component={RouterLink} to="/privacy-policy" target="_blank" variant="caption" color="textPrimary">
          {t('Privacy_Policy')}
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
