/* eslint-disable no-unused-vars */
// import { useEffect, useLayoutEffect, useState } from 'react';

// // material-ui
// import { useTheme } from '@mui/material/styles';
// import { Box, Typography, useMediaQuery } from '@mui/material';

// // project-imports
// import NavGroup from './NavGroup';
// import menuItem from 'menu-items';
// import { Menu } from 'menu-items/dashboard';

// import { useSelector } from 'store';
// import useConfig from 'hooks/useConfig';
// import { HORIZONTAL_MAX_ITEM } from 'config';
// import { MenuOrientation } from 'config';

// // ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

// const Navigation = () => {
//   const theme = useTheme();

//   const downLG = useMediaQuery(theme.breakpoints.down('lg'));

//   const { menuOrientation } = useConfig();
//   const { drawerOpen } = useSelector((state) => state.menu);

//   const [selectedItems, setSelectedItems] = useState('');
//   const [selectedLevel, setSelectedLevel] = useState(0);
//   const [menuItems, setMenuItems] = useState({ items: [] });

//   useEffect(() => {
//     handlerMenuItem();
//     // eslint-disable-next-line
//   }, []);

//   useLayoutEffect(() => {
//     setMenuItems(menuItem);
//     // eslint-disable-next-line
//   }, [menuItem]);

//   let getMenu = Menu();
//   const handlerMenuItem = () => {
//     const isFound = menuItem.items?.some((element) => {
//       if (element.id === 'group-dashboard') {
//         return true;
//       }
//       return false;
//     });

//     if (getMenu?.id !== undefined && !isFound) {
//       menuItem.items?.splice(0, 0, getMenu);
//       setMenuItems(menuItem);
//     }
//   };

//   const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

//   const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
//   let lastItemIndex = menuItems.items?.length - 1;
//   let remItems = [];
//   let lastItemId;

//   if (lastItem && lastItem < menuItems.items?.length) {
//     lastItemId = menuItems.items[lastItem - 1].id;
//     lastItemIndex = lastItem - 1;
//     remItems = menuItems.items?.slice(lastItem - 1, menuItems.items?.length)?.map((item) => ({
//       title: item.title,
//       elements: item.children,
//       icon: item.icon
//     }));
//   }

//   const navGroups = menuItems.items?.slice(0, lastItemIndex + 1)?.map((item) => {
//     switch (item.type) {
//       case 'group':
//         return (
//           <NavGroup
//             key={item.id}
//             setSelectedItems={setSelectedItems}
//             setSelectedLevel={setSelectedLevel}
//             selectedLevel={selectedLevel}
//             selectedItems={selectedItems}
//             lastItem={lastItem}
//             remItems={remItems}
//             lastItemId={lastItemId}
//             item={item}
//           />
//         );
//       default:
//         return (
//           <Typography key={item.id} variant="h6" color="error" align="center">
//             Fix - Navigation Group
//           </Typography>
//         );
//     }
//   });
//   return (
//     <Box
//       sx={{
//         pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
//         '& > ul:first-of-type': { mt: 0 },
//         display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
//       }}
//     >
//       {navGroups}
//     </Box>
//   );
// };

// export default Navigation;

import { useEffect, useLayoutEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

// project-imports
import NavGroup from './NavGroup';
import menuItemm from 'menu-items';
import { Menu } from 'menu-items/dashboard';

import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { MenuOrientation } from 'config';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);

  const [selectedItems, setSelectedItems] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menuItems, setMenuItems] = useState({ items: [] });
  const [loading, setLoading] = useState(true);
  const menuItem = menuItemm();
  useEffect(() => {
    handlerMenuItem();
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    const fetchMenuItems = () => {
      setMenuItems(menuItem);
      setLoading(false); // Set loading to false once menu items are set
    };

    fetchMenuItems();
    // eslint-disable-next-line
  }, []);

  let getMenu = Menu();
  const handlerMenuItem = () => {
    const isFound = menuItem.items?.some((element) => {
      if (element.id === 'group-dashboard') {
        return true;
      }
      return false;
    });

    if (getMenu?.id !== undefined && !isFound) {
      menuItem.items?.splice(0, 0, getMenu);
      setMenuItems(menuItem);
    }
  };

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items?.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItems.items?.length) {
    lastItemId = menuItems.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items?.slice(lastItem - 1, menuItems.items?.length)?.map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const navGroups = menuItems.items?.slice(0, lastItemIndex + 1)?.map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;

// Navigation.js
// import React, { useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { Box, Typography, useMediaQuery } from '@mui/material';
// import { useSelector } from 'react-redux';
// import useConfig from 'hooks/useConfig';
// import { HORIZONTAL_MAX_ITEM, MenuOrientation } from 'config';
// import NavGroup from './NavGroup';
// import MenuItems from 'menu-items'; // Ensure the import path is correct
// import { Menu } from 'menu-items/dashboard';

// const Navigation = () => {
//   const theme = useTheme();
//   const downLG = useMediaQuery(theme.breakpoints.down('lg'));
//   const { menuOrientation } = useConfig();
//   const { drawerOpen } = useSelector((state) => state.menu);

//   const [selectedItems, setSelectedItems] = useState('');
//   const [selectedLevel, setSelectedLevel] = useState(0);
//   const [menuItems, setMenuItems] = useState({ items: [] });
//   const [loading, setLoading] = useState(true); // Loading state

//   const menuItemsResult = MenuItems();
//   // console.log(menuItems); // Debugging
//   useEffect(() => {
//     const fetchMenuItems = () => {
//       setMenuItems(menuItemsResult);
//       setLoading(false); // Set loading to false once menu items are set
//     };

//     fetchMenuItems();
//   }, []);

//   const getMenu = Menu();
//   useEffect(() => {
//     if (!loading) {
//       const isFound = menuItems.items?.some((element) => element.id === 'group-dashboard');

//       if (getMenu?.id !== undefined && !isFound) {
//         setMenuItems((prevMenuItems) => ({
//           ...prevMenuItems,
//           items: [getMenu, ...prevMenuItems.items]
//         }));
//       }
//     }
//   }, [getMenu]);

//   //   let getMenu = Menu();
//   //   const handlerMenuItem = () => {
//   //     const isFound = menuItem.items?.some((element) => {
//   //       if (element.id === 'group-dashboard') {
//   //         return true;
//   //       }
//   //       return false;
//   //     });

//   //     if (getMenu?.id !== undefined && !isFound) {
//   //       menuItem.items?.splice(0, 0, getMenu);
//   //       setMenuItems(menuItem);
//   //     }
//   //   };

//   const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

//   const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
//   let lastItemIndex = menuItems.items?.length - 1;
//   let remItems = [];
//   let lastItemId;

//   if (lastItem && lastItem < menuItems.items?.length) {
//     lastItemId = menuItems.items[lastItem - 1].id;
//     lastItemIndex = lastItem - 1;
//     remItems = menuItems.items.slice(lastItem - 1).map((item) => ({
//       title: item.title,
//       elements: item.children,
//       icon: item.icon
//     }));
//   }

//   const navGroups = menuItems.items?.slice(0, lastItemIndex + 1)?.map((item) => {
//     switch (item.type) {
//       case 'group':
//         return (
//           <NavGroup
//             key={item.id}
//             setSelectedItems={setSelectedItems}
//             setSelectedLevel={setSelectedLevel}
//             selectedLevel={selectedLevel}
//             selectedItems={selectedItems}
//             lastItem={lastItem}
//             remItems={remItems}
//             lastItemId={lastItemId}
//             item={item}
//           />
//         );
//       default:
//         return (
//           <Typography key={item.id} variant="h6" color="error" align="center">
//             Fix - Navigation Group
//           </Typography>
//         );
//     }
//   });

//   if (loading) {
//     // Optionally, you can add a loading spinner or placeholder here
//     return <Typography>Loading...</Typography>;
//   }

//   return (
//     <Box
//       sx={{
//         pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
//         '& > ul:first-of-type': { mt: 0 },
//         display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
//       }}
//     >
//       {navGroups}
//     </Box>
//   );
// };

// export default Navigation;
