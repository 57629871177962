// store/blockIpSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notify } from 'utils/notify';
import { getRequest } from 'utils/fetchMethods';

export const getBlockIps = createAsyncThunk('blockIp/getBlockIps', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/users/ip/block`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res.block_ip;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

const blockIpSlice = createSlice({
  name: 'blockIp',
  initialState: {
    dataBlockIps: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlockIps.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBlockIps.fulfilled, (state, action) => {
        state.loading = false;
        state.dataBlockIps = action.payload;
      })
      .addCase(getBlockIps.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default blockIpSlice.reducer;
