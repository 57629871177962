import PropTypes from 'prop-types';

// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project-imports
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { Trash } from 'iconsax-react';
import { useContext } from 'react';
import { AdsContext } from 'components/contextApi/AdsContext';
// import { useContext } from 'react';
// import { WhitelistContext } from 'components/contextApi/WhitelistContext';

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function DeleteCompetitorModal({ title, open, handleClose }) {
  // const { deleteWhitelist, GetWhitelists } = useContext(WhitelistContext);
  // const dispatch = useDispatch();
  const { deleteCometitor,GetCompetitors } = useContext(AdsContext);
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color="error" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            <Trash variant="Bold" />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              Are you sure you want to delete?
            </Typography>
            {/* <Typography align="center">
              By deleting
              <Typography variant="subtitle1" component="span">
                {' '}
                &quot;{title}&quot;{' '}
              </Typography>
              user, all task assigned to that user will also be deleted.
            </Typography> */}
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={() => handleClose(false)} color="secondary" variant="outlined">
              Cancel
            </Button>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={() => {
                deleteCometitor({ user_id: title });
                GetCompetitors();
                // dispatch(deleteWhitelist({ ip: title })), dispatch(getWhitelists());
                handleClose(true);
                // deleteWhitelist({ ip: title }),
                // GetWhitelists();
              }}
              autoFocus
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

DeleteCompetitorModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
