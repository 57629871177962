/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  // FormControlLabel,
  FormLabel,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  // Switch,
  TextField,
  Tooltip,
  Typography,
  Dialog
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
import IconButton from 'components/@extended/IconButton';
import { Trash } from 'iconsax-react';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
// import { Camera, Trash } from 'iconsax-react';

// const avatarImage = require.context('assets/images/users', true);

// constant
const getInitialValues = (account) => {
  const newAccount = {
    account_name: '',
    Reason: ''
  };

  if (account) {
    newAccount.name = account.fatherName;
    newAccount.location = account.address;
    return _.merge({}, newAccount, account);
  }

  return newAccount;
};

const allStatus = ['Complicated', 'Single', 'Relationship'];

// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const CancelPlan = ({ account, onCancel }) => {
  const theme = useTheme();
  const isCreating = !account;

  const AccountSchema = Yup.object().shape({
    account_name: Yup.string().max(255).required('Account name is required'),
    Reason: Yup.string().max(255).required('Reason is required')
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    onCancel();
  };

  const formik = useFormik({
    initialValues: getInitialValues(account),
    validationSchema: AccountSchema,
    onSubmit: (values, { setSubmitting }) => {
      onCancel();
      handleClickOpen();
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{account ? 'Edit Customer' : 'New Account'}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={1}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Reason for cancellation</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            label="Your Reason"
                            name="Reason"
                            id="Reason"
                            // onChange={handleChange}
                            // value={values.Reason}
                            {...getFieldProps('Reason')}
                            error={Boolean(touched.Reason && errors.Reason)}
                            helperText={touched.Reason && errors.Reason}
                          >
                            <MenuItem value={'Marketing & Advertising'}>Marketing & Advertising</MenuItem>
                            <MenuItem value={'Business & Information'}>Business & Information</MenuItem>
                            <MenuItem value={'Education'}>Education</MenuItem>
                            <MenuItem value={'Finance & Insurance'}>Finance & Insurance</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        {/* <InputLabel htmlFor="account-name">Details of the cancellation reason</InputLabel> */}
                        <TextField
                          rows={5}
                          multiline
                          fullWidth
                          id="account-name"
                          placeholder="Details of the cancellation reason"
                          {...getFieldProps('account_name')}
                          error={Boolean(touched.account_name && errors.account_name)}
                          helperText={touched.account_name && errors.account_name}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {!isCreating && (
                    <Tooltip title="Delete account" placement="top">
                      <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                        <Trash variant="Bold" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                      Add
                    </Button>
                  </Stack>
                  <Dialog
                    open={open}
                    onClose={() => handleClose(false)}
                    keepMounted
                    TransitionComponent={PopupTransition}
                    maxWidth="xs"
                    aria-labelledby="column-delete-title"
                    aria-describedby="column-delete-description"
                  >
                    <DialogContent sx={{ mt: 2, my: 1 }}>
                      <Stack alignItems="center" spacing={3.5}>
                        <Stack spacing={2}>
                          <Typography variant="h4" align="center">
                            Subscription Canceled
                          </Typography>
                          <Typography align="center">Your plan will be canceled, but is still available until the may 8, 2024</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                          <Button fullWidth onClick={() => handleClose(false)} color="secondary" variant="outlined">
                            Close
                          </Button>
                        </Stack>
                      </Stack>
                    </DialogContent>
                  </Dialog>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {!isCreating && <AlertCustomerDelete title={account.fatherName} open={openAlert} handleClose={handleAlertClose} />}
    </>
  );
};

CancelPlan.propTypes = {
  account: PropTypes.any,
  onCancel: PropTypes.func
};

export default CancelPlan;
