import PropTypes from 'prop-types';

// material-ui
import { Button, Grid, InputLabel, Stack, Typography, TextField } from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project-imports
import AnimateButton from 'components/@extended/AnimateButton';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

const validationSchema = yup.object({
  AccountId: yup.string().required('Account ID is required')
});

// ==============================|| VALIDATION WIZARD - ADDRESS  ||============================== //

const ConnectAccount = ({ shippingData, setShippingData, handleNext, setErrorIndex }) => {
  const formik = useFormik({
    initialValues: {
      AccountId: shippingData.AccountId
    },
    validationSchema,
    onSubmit: (values) => {
      setShippingData({
        AccountId: values.AccountId
      });
      handleNext();
    }
  });

  const handleChange = (event) => {
    let inputValue = event.target.value.replace(/-/g, ''); // Remove existing hyphens
    inputValue = inputValue.slice(0, 10); // Limit input to 10 characters
    const formattedValue = inputValue
      .replace(/\D/g, '') // Remove non-numeric characters
      .replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3') // Insert hyphens after first two groups of three numbers
      .replace(/(\d{3})(?=\d{4}$)/, '$1-'); // Insert hyphen after every 3 numbers except for the last group of four
    formik.setFieldValue('AccountId', formattedValue);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Connect your Google Ads account
      </Typography>
      <form onSubmit={formik.handleSubmit} id="validation-forms">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1}>
              <InputLabel>Please enter the Google Ads account ID number you wish to protect</InputLabel>
              <TextField
                id="AccountId"
                name="AccountId"
                placeholder="Google Ads account ID e.g. 123-456-7890 *"
                value={formik.values.AccountId}
                onChange={handleChange}
                error={formik.touched.AccountId && Boolean(formik.errors.AccountId)}
                helperText={formik.touched.AccountId && formik.errors.AccountId}
                fullWidth
                autoComplete="given-name"
                inputProps={{ maxLength: 12 }} // Set maximum input length to 12 to accommodate hyphens
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1}>
              <Typography variant="P" gutterBottom sx={{ mb: 2 }}>
                <MdOutlineRemoveRedEye /> You can find your Google Ads ID number at the top right corner of your <br /> Google Ads account
                window
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button variant="contained" sx={{ my: 3, ml: 1 }} type="submit" onClick={() => setErrorIndex(1)}>
                  Next
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

ConnectAccount.propTypes = {
  shippingData: PropTypes.object,
  setShippingData: PropTypes.func,
  handleNext: PropTypes.func,
  setErrorIndex: PropTypes.func
};

export default ConnectAccount;
