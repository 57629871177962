/* eslint-disable no-unused-vars */
import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AdsTransparency from 'pages/clicksPages/AdsTransparency/AdsTransparency';
import Competitors from 'pages/clicksPages/competitors/Competitors';
import Add_Competitors from 'pages/clicksPages/competitors/Add_Competitors';
import Keyword from 'pages/clicksPages/keywordSearch/Keyword';
import CompetitorDetails from 'pages/clicksPages/competitors/CompetitorDetails';
import CompleteInfo from 'pages/completeInfo/CompleteInfo';
import Subscription from 'pages/subscription/Subscription';
import CampaignsDetails from 'pages/clicksPages/campaigns/CampaignsDetails';
import ClickDetails from 'pages/clicksPages/clicks/ClickDetails';
import TryFree from 'pages/TryFree/TryFree';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

// const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/auth1/register')));
// const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/forgot-password')));
// const AuthResetPassword = Loadable(lazy(() => import('pages/auth/auth1/reset-password')));
// const AuthCheckMail = Loadable(lazy(() => import('pages/auth/auth1/check-mail')));
// const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/auth1/code-verification')));
// const AuthCodeReset = Loadable(lazy(() => import('pages/auth/auth1/code-reset')));

const PricingPage = Loadable(lazy(() => import('pages/extra-pages/price/price1')));
const Accounts = Loadable(lazy(() => import('pages/clicksPages/Accounts/Accounts')));
const Campaigns = Loadable(lazy(() => import('pages/clicksPages/campaigns/Campaigns')));
const Clicks = Loadable(lazy(() => import('pages/clicksPages/clicks/Clicks')));
const BlockedIp = Loadable(lazy(() => import('pages/clicksPages/BlockedIp/BlockedIp')));
const IpWhiteList = Loadable(lazy(() => import('pages/clicksPages/IpWhiteList/IpWhiteList')));
const BlockingSettings = Loadable(lazy(() => import('pages/clicksPages/blockingSettings/BlockingSettings')));
const FormsPluginsEditor = Loadable(lazy(() => import('pages/forms/plugins/editor')));

const MainRoutes = (langPrefix) => ({
  path: `/${langPrefix}`,
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'pages',
      children: [
        {
          path: 'editor',
          element: <FormsPluginsEditor />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        },
        {
          path: 'accounts',
          element: <Accounts />
        },
        {
          path: 'campaigns',
          element: <Campaigns />
        },
        {
          path: 'campaigns/:id',
          element: <CampaignsDetails />
        },
        {
          path: 'clicks',
          element: <Clicks />
        },
        {
          path: 'click/:id',
          element: <ClickDetails />
        },
        {
          path: 'BlockedIp',
          element: <BlockedIp />
        },
        {
          path: 'IpWhiteList',
          element: <IpWhiteList />
        },
        {
          path: 'BlockingSettings',
          element: <BlockingSettings />
        },
        {
          path: 'ads-transparency',
          element: <AdsTransparency />
        },
        {
          path: 'Competitors',
          element: <Competitors />
        },
        {
          path: 'competitor/:id',
          element: <CompetitorDetails />
        },
        {
          path: 'add-competitors',
          element: <Add_Competitors />
        },
        {
          path: 'keyword',
          element: <Keyword />
        },
        {
          path: 'price',
          element: <PricingPage />
        },
        {
          path: 'CompleteInfo',
          element: <CompleteInfo />
        },
        {
          path: 'subscription',
          element: <Subscription />
        },
        {
          path: 'profile',
          element: <AccountProfile />,
          children: [
            {
              path: '',
              element: <AccountTabProfile />
            },
            {
              path: 'personal',
              element: <AccountTabPersonal />
            },
            {
              path: 'my-account',
              element: <AccountTabAccount />
            },
            {
              path: 'password',
              element: <AccountTabPassword />
            },
            {
              path: 'role',
              element: <AccountTabRole />
            },
            {
              path: 'settings',
              element: <AccountTabSettings />
            }
          ]
        }
      ]
    }
  ]
});
// {
//   path: '/pages',
//   element: (
//     <AuthGuard>
//       <MainLayout />
//     </AuthGuard>
//   ),
//   children: [
//     {
//       path: 'editor',
//       element: <FormsPluginsEditor />
//     }
//   ]
// },
// {
//   path: '/',
//   children: [
//     {
//       path: 'setSubscription',
//       element: <TryFree />
//     }
//   ]
// }

export default MainRoutes;

// import { lazy } from 'react';

// // project-imports
// import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
// import Loadable from 'components/Loadable';
// import AuthGuard from 'utils/route-guard/AuthGuard';
// import AdsTransparency from 'pages/clicksPages/AdsTransparency/AdsTransparency';
// import Competitors from 'pages/clicksPages/competitors/Competitors';
// import Add_Competitors from 'pages/clicksPages/competitors/Add_Competitors';
// import Keyword from 'pages/clicksPages/keywordSearch/Keyword';
// import CompetitorDetails from 'pages/clicksPages/competitors/CompetitorDetails';
// import TryFree from 'pages/TryFree/TryFree';
// import CompleteInfo from 'pages/completeInfo/CompleteInfo';
// import Subscription from 'pages/subscription/Subscription';
// import CampaignsDetails from 'pages/clicksPages/campaigns/CampaignsDetails';
// import ClickDetails from 'pages/clicksPages/clicks/ClickDetails';

// // render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
// const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
// const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
// const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
// const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
// const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
// const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
// const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

// // render - charts & map

// // pages routing
// const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/auth1/register')));
// const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/forgot-password')));
// const AuthResetPassword = Loadable(lazy(() => import('pages/auth/auth1/reset-password')));
// const AuthCheckMail = Loadable(lazy(() => import('pages/auth/auth1/check-mail')));
// const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/auth1/code-verification')));
// const AuthCodeReset = Loadable(lazy(() => import('pages/auth/auth1/code-reset')));

// // render - sample page

// const PricingPage = Loadable(lazy(() => import('pages/extra-pages/price/price1')));
// const Accounts = Loadable(lazy(() => import('pages/clicksPages/Accounts/Accounts')));
// const Campaigns = Loadable(lazy(() => import('pages/clicksPages/campaigns/Campaigns')));
// const Clicks = Loadable(lazy(() => import('pages/clicksPages/clicks/Clicks')));
// const BlockedIp = Loadable(lazy(() => import('pages/clicksPages/BlockedIp/BlockedIp')));
// const IpWhiteList = Loadable(lazy(() => import('pages/clicksPages/IpWhiteList/IpWhiteList')));
// const BlockingSettings = Loadable(lazy(() => import('pages/clicksPages/blockingSettings/BlockingSettings')));
// const FormsPluginsEditor = Loadable(lazy(() => import('pages/forms/plugins/editor')));
// // ==============================|| MAIN ROUTES ||============================== //

// const MainRoutes = {
//   path: '/',
//   children: [
//     {
//       path: '/',
//       element: (
//         <AuthGuard>
//           <MainLayout />
//         </AuthGuard>
//       ),
//       children: [
//         {
//           path: 'dashboard',
//           children: [
//             {
//               path: 'default',
//               element: <DashboardDefault />
//             },
//             {
//               path: 'accounts',
//               element: <Accounts />
//             },
//             {
//               path: 'campaigns',
//               element: <Campaigns />
//             },
//             {
//               path: 'campaigns/:id',
//               element: <CampaignsDetails />
//             },
//             {
//               path: 'clicks',
//               element: <Clicks />
//             },
//             {
//               path: 'click/:id',
//               element: <ClickDetails />
//             },
//             {
//               path: 'BlockedIp',
//               element: <BlockedIp />
//             },
//             {
//               path: 'IpWhiteList',
//               element: <IpWhiteList />
//             },
//             {
//               path: 'BlockingSettings',
//               element: <BlockingSettings />
//             },
//             {
//               path: 'ads-transparency',
//               element: <AdsTransparency />
//             },
//             {
//               path: 'Competitors',
//               element: <Competitors />
//             },
//             {
//               path: 'competitor/:id',
//               element: <CompetitorDetails />
//             },
//             {
//               path: 'add-competitors',
//               element: <Add_Competitors />
//             },
//             {
//               path: 'keyword',
//               element: <Keyword />
//             },
//             {
//               path: 'price',
//               element: <PricingPage />
//             },
//             {
//               path: 'CompleteInfo',
//               element: <CompleteInfo />
//             },
//             {
//               path: 'subscription',
//               element: <Subscription />
//             },
//             {
//               path: 'profile',
//               element: <AccountProfile />,
//               children: [
//                 {
//                   path: '',
//                   element: <AccountTabProfile />
//                 },
//                 {
//                   path: 'personal',
//                   element: <AccountTabPersonal />
//                 },
//                 {
//                   path: 'my-account',
//                   element: <AccountTabAccount />
//                 },
//                 {
//                   path: 'password',
//                   element: <AccountTabPassword />
//                 },
//                 {
//                   path: 'role',
//                   element: <AccountTabRole />
//                 },
//                 {
//                   path: 'settings',
//                   element: <AccountTabSettings />
//                 }
//               ]
//             }
//           ]
//         }
//       ]
//     },
//     {
//       path: '/pages',
//       element: (
//         <AuthGuard>
//           <MainLayout />
//         </AuthGuard>
//       ),
//       children: [
//         {
//           path: 'editor',
//           element: <FormsPluginsEditor />
//         }
//       ]
//     },

//     {
//       path: '/',
//       children: [
//         {
//           path: 'setSubscription',
//           element: <TryFree />
//         }
//       ]
//     },

//     {
//       path: '/auth',
//       element: <CommonLayout />,
//       children: [
//         {
//           path: 'login',
//           element: <AuthLogin />
//         },
//         {
//           path: 'register',
//           element: <AuthRegister />
//         },
//         {
//           path: 'forgot-password',
//           element: <AuthForgotPassword />
//         },
//         {
//           path: 'reset-password',
//           element: <AuthResetPassword />
//         },
//         {
//           path: 'check-mail',
//           element: <AuthCheckMail />
//         },
//         {
//           path: 'code-verification',
//           element: <AuthCodeVerification />
//         },
//         {
//           path: 'code-reset',
//           element: <AuthCodeReset />
//         }
//       ]
//     }
//   ]
// };

// export default MainRoutes;
