import { useContext, useEffect, useState } from 'react';

// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Customization from 'components/Customization';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

import { dispatch } from 'store';
import { fetchMenu } from 'store/reducers/menu';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { ConfigContext } from 'contexts/ConfigContext';
import i18n from 'i18n';
import useConfig from 'hooks/useConfig';
import { ThemeDirection } from 'config';

// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const { themeDirection, onChangeDirection } = useConfig();

  console.log(themeDirection);

  const { ...config } = useContext(ConfigContext);

  const langLocal = config.i18n;
  useEffect(() => {
    if (langLocal && ['en', 'ar'].includes(langLocal)) {
      i18n.changeLanguage(langLocal); // Set the language from local storage
    }
  }, [langLocal, i18n]);
  useEffect(() => {
    if (langLocal === 'ar') {
      onChangeDirection(ThemeDirection.RTL);
    } else if (langLocal === 'en') {
      onChangeDirection(ThemeDirection.LTR);
    }
  }, [langLocal]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });
  }, []);
  const location = useLocation();
  // console.log(location.pathname.includes('dashboard'));
  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <Helmet>
        <title>Gardifi</title>
        <meta
          name="description"
          content="Welcome to the Gardifi! This centralized hub empowers you to oversee and optimize various aspects of your online presence effortlessly. From managing accounts and campaigns to monitoring click activities and IP security, this dashboard offers a comprehensive suite of tools to streamline your operations. Stay informed, take control, and make data-driven decisions with ease, all from one convenient interface."
        />
      </Helmet>
      <ToastContainer />
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  {(location.pathname.includes('dashboard') || location.pathname.includes('pages')) && <Customization />}
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
