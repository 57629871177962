// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Story, Fatrows, PresentionChart } from 'iconsax-react';
import { FaMouse } from 'react-icons/fa';
import { MdCampaign } from 'react-icons/md';
import { MdManageAccounts } from 'react-icons/md';
import { MdBlock } from 'react-icons/md';
import { FaListAlt } from 'react-icons/fa';
import { MdOutlineSettingsSuggest } from 'react-icons/md';
import { SiGoogleads } from 'react-icons/si';
import { RiAdminFill } from 'react-icons/ri';
import { FaRegHand } from "react-icons/fa6";


// icons
const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart,
  click: FaMouse,
  blockSettings: MdOutlineSettingsSuggest,
  list: FaListAlt,
  blockip: MdBlock,
  accounts: MdManageAccounts,
  campaign: MdCampaign,
  SiGoogleads,
  RiAdminFill,
  FaRegHand
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const clicks = {
  id: 'group-widget',
  title: <FormattedMessage id="clicks" />,
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      url: '/admin/users',
      icon: icons.accounts
    },
    {
      id: 'admin_users',
      title: <FormattedMessage id="admin_users" />,
      type: 'item',
      url: '/admin/admin_users',
      icon: icons.RiAdminFill
    },
    {
      id: 'roles',
      title: <FormattedMessage id="roles" />,
      type: 'item',
      url: '/admin/roles',
      icon: icons.FaRegHand
    }
    // {
    //   id: 'Ads transparency',
    //   title: <FormattedMessage id="Ads transparency" />,
    //   type: 'item',
    //   url: '/dashboard/ads-transparency',
    //   icon: icons.SiGoogleads
    // }
  ]
};

export default clicks;
