/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// project-imports
import MainCard from 'components/MainCard';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Select
} from '@mui/material';
import { red } from '@mui/material/colors';
import LoadingButton from 'components/@extended/LoadingButton';
import { AdsContext } from 'components/contextApi/AdsContext';
import Lottie from 'lottie-react';
import notFoundDark from 'assets/notFound.png';
import notFoundLight from 'assets/notFoundL.png';
import loading from 'assets/loading.json';
import AsyncSelect from 'react-select/async';
import { Eye } from 'iconsax-react';

// ==============================|| CUSTOMER - LIST ||============================== //

const Keyword = () => {
  const theme = useTheme();
  const { adsDatasearch, loadingDatasearch, tokenPagesearch, allDatasearch, setAdsDatasearch } = useContext(AdsContext);
  const { getSearchCountries, searchCountries } = useContext(AdsContext);
  const { searchCompatitors, setSearchCompatitors, getSearchCompatitors } = useContext(AdsContext);
  const [domain, setDomain] = useState();
  const [language, setLanguage] = useState('ar');
  // const [isValid, setIsValid] = useState(true);

  const handleChangeDomain = (event) => {
    const { value } = event.target;
    setDomain(value);
  };

  const isDarkMode = theme.palette.type === 'dark';
  const customStyles = {
    control: (provided, state) => ({
      padding: '5px 0',
      ...provided,
      borderRadius: '10px',
      borderColor: theme.palette.grey[800],
      boxShadow: 'none',
      backgroundColor: state.isDisabled
        ? theme.palette.background.paper
        : state.isFocused
        ? theme.palette.background.paper
        : isDarkMode
        ? theme.palette.background.paper
        : theme.palette.background.paper,
      '&:hover': {
        borderColor: theme.palette.grey[800]
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary
    }),
    input: (provided) => ({
      ...provided,
      color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.palette.action.selected : state.isFocused ? theme.palette.action.hover : 'transparent',
      color: state.isSelected ? theme.palette.common.white : theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.primary
      }
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? theme.palette.background.default : theme.palette.background.paper,
      boxShadow: 'none'
    })
  };
  const filterColors = (inputValue) => {
    return searchCountries?.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      getSearchCountries({ search: inputValue });
      callback(filterColors(inputValue));
    }, 1000);
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
  };

  const handleClickUrl = (links) => {
    window.open(`https://${links}`, '_blank');
  };

  const { postCompetitors } = useContext(AdsContext);

  return (
    <MainCard content={false}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setAdsDatasearch([]);
          getSearchCompatitors({ city: selectedOption, search: domain, language });
        }}
      >
        <Stack spacing={0} alignItems="center" sx={{ p: 3, minHeight: '100vh' }}>
          <Grid item container alignItems={'center'} spacing={1}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                placeholder="Search by Keyword"
                id="outlined-end-adornment"
                fullWidth
                onChange={handleChangeDomain}
                value={domain}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <AsyncSelect
                loadOptions={loadOptions}
                defaultOptions
                styles={customStyles}
                onChange={handleOptionChange}
                placeholder="location..."
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Select Language</em>
                  </MenuItem>
                  <MenuItem value={'en'}>English</MenuItem>
                  <MenuItem value={'ar'}>Arabic</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Device</InputLabel>
                <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper">
                  <MenuItem value="">
                    <em>Select Device</em>
                  </MenuItem>
                  <MenuItem value={'Mobile'}>Mobile</MenuItem>
                  <MenuItem value={'Desktop'}>Desktop</MenuItem>
                  <MenuItem value={'Tablet'}>Tablet</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button sx={{ display: 'block', width: '100%', height: '100%' }} variant="contained" color="success" type="submit">
                Search
              </Button>
            </Grid>
          </Grid>
          {loadingDatasearch && adsDatasearch?.length === 0 ? (
            <Lottie animationData={loading} loop={true} />
          ) : allDatasearch && allDatasearch.length === 0 && adsDatasearch?.length === 0 ? (
            <img src={theme.palette.mode === 'light' ? notFoundLight : notFoundDark} alt="not found" style={{ maxWidth: '100%' }} />
          ) : // <Lottie animationData={nodataAnimation} loop={true} />
          adsDatasearch?.length > 0 ? (
            <>
              <Grid container spacing={2} sx={{ margin: '20px 0' }}>
                {adsDatasearch?.map((item, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    {/* <h1>{item.links}</h1> */}
                    <Card sx={{ maxWidth: 345, cursor: 'pointer' }} onClick={() => handleClickUrl(item.links)}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {item?.title.charAt(0)}
                          </Avatar>
                        }
                        title={item?.title}
                        subheader={item?.description}
                      />
                      <div className="linkLists">
                        <ul>
                          {item?.sitelink?.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </Card>
                    <CardActions>
                      <Button size="small" onClick={() => postCompetitors({ type: 1, value: item.links })}>
                        Add Competitors
                      </Button>
                    </CardActions>
                  </Grid>
                ))}
              </Grid>
              <LoadingButton
                loading={loadingDatasearch}
                variant="contained"
                loadingPosition="start"
                disabled={loadingDatasearch}
                startIcon={<Eye />}
                onClick={() => getSearchCompatitors({ city: selectedOption, search: domain, href: tokenPagesearch })}
              >
                {loadingDatasearch ? 'Loading...' : 'See More'}
              </LoadingButton>
            </>
          ) : (
            ''
          )}
        </Stack>
      </form>
      {/* add customer dialog */}
    </MainCard>
  );
};

Keyword.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  fatherName: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default Keyword;
