import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
// import useAuth from 'hooks/useAuth';
import { AuthContext } from 'components/contextApi/RegisterationContext';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  // const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuth } = useContext(AuthContext);
  useEffect(() => {
    if (!isAuth) {
      navigate('/login', {
        state: {
          from: location.pathname
        },
        replace: true
      });
    }
    //  else if (userInfo && userInfo?.is_verified === 0) {
    //   // console.log('very veynbhksdfbvbsfvsdfvsdvsdv')
    //   navigate('code-verification', {
    //     state: {
    //       from: location.pathname
    //     },
    //     replace: true
    //   });
    //   // navigate('code-verification', { replace: true });
    // }
  }, [isAuth, navigate, location]);
  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
