// store/pagesSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notify } from 'utils/notify';
import { getRequest } from 'utils/fetchMethods';

// Async thunk for fetching pages
export const getPage = createAsyncThunk('pages/getPage', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/page-admin/getPage`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

// Slice to manage pages state
const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    dataPages: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPage.fulfilled, (state, action) => {
        state.loading = false;
        state.dataPages = action.payload;
      })
      .addCase(getPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default pagesSlice.reducer;
