import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto', flexWrap: 'wrap' }}>
    <Typography variant="caption">&copy; Gardifi ♥</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <Link component={RouterLink} to="/" target="_blank" variant="caption" color="textPrimary">
        Home
      </Link>
      <Link component={RouterLink} to="/about-us" target="_blank" variant="caption" color="textPrimary">
        About us
      </Link>
      <Link component={RouterLink} to="/contact-us" target="_blank" variant="caption" color="textPrimary">
        Contact us
      </Link>
      <Link component={RouterLink} to="/privacy-policy" target="_blank" variant="caption" color="textPrimary">
        Privacy Police
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
