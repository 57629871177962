import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notify } from 'utils/notify';
import { getRequest, postRequest } from 'utils/fetchMethods';

export const getWhitelists = createAsyncThunk('whitelist/getWhitelists', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/users/ip/list`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res.list;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

export const postWhitelist = createAsyncThunk('whitelist/postWhitelist', async (body, { rejectWithValue }) => {
  try {
    const res = await postRequest(body, `/users/ip/list`);
    if (!res) {
      throw new Error(res?.message);
    }
    notify('ip added successfully', 'success');
    return res;
  } catch (error) {
    notify('The ip field must be a valid IP address', 'error');
    return rejectWithValue(error.message);
  }
});

export const deleteWhitelist = createAsyncThunk('whitelist/deleteWhitelist', async (body, { rejectWithValue }) => {
  try {
    const res = await postRequest(body, `/users/ip/isDeleted`);
    if (!res) {
      throw new Error(res?.message);
    }
    notify('ip deleted successfully', 'success');
    return res;
  } catch (error) {
    notify('try again', 'error');
    return rejectWithValue(error.message);
  }
});

const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState: {
    dataWhitelists: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWhitelists.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWhitelists.fulfilled, (state, action) => {
        state.loading = false;
        state.dataWhitelists = action.payload;
      })
      .addCase(getWhitelists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postWhitelist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postWhitelist.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postWhitelist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteWhitelist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteWhitelist.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteWhitelist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default whitelistSlice.reducer;
