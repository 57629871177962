/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { Fragment, useState } from 'react';
import { Grid, Typography, Box, ListItemText, ListItem, List, Button, Stack, Chip, Switch } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';

// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import './payment.css';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
// assets
const Pricing = ({ title }) => {
  const { t } = useTranslation();

  // const stripePromise = loadStripe(
  //   'pk_test_51P6XZAI93eaQ6yrBb4GVE59Wq648v7Qw0RdxDZMrD0YjbnP4clvP3aOp1n2nBy5KuqqrMzuqArUMyMCELYaWIMMB00AVXTcS4h'
  // );

  const theme = useTheme();
  const [timePeriod, setTimePeriod] = useState(true);
  const priceListDisable = {
    opacity: 0.4,
    textDecoration: 'line-through'
  };

  const priceActivePlan = {
    padding: 3,
    borderRadius: 1,
    bgcolor: theme.palette.primary.lighter
  };
  const price = {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: 1
  };
  const plans = [
    {
      active: false,
      title: t('Basic'),
      description: `03 ${t('Services')}`,
      price: 69,
      permission: [0, 1, 2]
    },
    {
      active: true,
      title: t('Standard'),
      description: `05 ${t('Services')}`,
      price: 129,
      permission: [0, 1, 2, 3, 4]
    },
    {
      active: false,
      title: t('Premium'),
      description: `08 ${t('Services')}`,
      price: 599,
      permission: [0, 1, 2, 3, 4, 5, 6, 7]
    }
  ];
  const planList = [
    'One End Product', // 0
    'No attribution required', // 1
    'TypeScript', // 2
    'Figma Design Resources', // 3
    'Create Multiple Products', // 4
    'Create a SaaS Project', // 5
    'Resale Product', // 6
    'Separate sale of our UI Elements?' // 7
  ];
  const planBasic = ['Single website', 'Single PPC account', 'Up to 2 members', 'Up to 5k spend', 'Unlimited clicks'];
  const planStandard = ['Up to 3 websites', 'Two PPC accounts / website', 'Up to 5 members', 'Up to 50k spend', 'Unlimited clicks'];
  const planPremium = ['Unlimited websites', 'Unlimited PPC', 'Unlimited members', 'Up to 100k spend', 'Unlimited clicks'];

  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Gardifi || pricing</title>
        <meta
          name="description"
          content="Welcome to Gardifi! Explore our range of packages tailored to suit your gardening needs. From basic maintenance to full landscape design, we have the perfect package for you. Select the best package that fits your requirements and let us transform your outdoor space into a lush green paradise!"
        />
      </Helmet>
      <Box
        container
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        className="common_about"
      >
        <Typography variant="h2" component="h2">
          <span>{title}</span>
        </Typography>
        <Box
          container
          sx={{
            display: 'flex',
            //   position: 'absolute',
            //   bottom: { xs: -30, sm: 0 },
            //   left: 0,
            //   right: 0,
            margin: '80px 0 0',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.background.paper,
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Grid
            container
            spacing={0}
            justifyContent="center"
            sx={{
              width: '70%',
              padding: '30px 0',
              '& > .MuiGrid-item': {
                '& img': {
                  padding: 1.3
                }
              }
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
                  <Stack spacing={0}>
                    <Typography variant="h5">{t('packageH')}</Typography>
                    <Typography color="textSecondary">{t('packageP')}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Typography variant="subtitle1" color={timePeriod ? 'textSecondary' : 'textPrimary'}>
                      {t('Billed_Yearly')}
                    </Typography>
                    <Switch checked={timePeriod} onChange={() => setTimePeriod(!timePeriod)} inputProps={{ 'aria-label': 'container' }} />
                    <Typography variant="subtitle1" color={timePeriod ? 'textPrimary' : 'textSecondary'}>
                      {t('Billed_Monthly')}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item container spacing={3} xs={12} alignItems="center">
                {plans.map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <MainCard>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box sx={plan.active ? priceActivePlan : { padding: 3 }}>
                            <Grid container spacing={3}>
                              {plan.active && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                  <Chip label={t('Popular')} color="success" />
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Stack spacing={0} textAlign="center">
                                  <Typography variant="h4">{plan.title}</Typography>
                                  <Typography>{plan.description}</Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={12}>
                                <Stack spacing={0} alignItems="center">
                                  {timePeriod && (
                                    <Typography variant="h2" sx={price}>
                                      ${plan.price}
                                    </Typography>
                                  )}
                                  {!timePeriod && (
                                    <Typography variant="h2" sx={price}>
                                      ${plan.price * 12 - 99}
                                    </Typography>
                                  )}
                                  <Typography variant="h6" color="textSecondary">
                                    {t('Lifetime')}
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  color={plan.active ? 'primary' : 'secondary'}
                                  variant={plan.active ? 'contained' : 'outlined'}
                                  fullWidth
                                  onClick={() => navigate('/dashboard/price')}
                                >
                                  {t('Order_Now')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <List
                            sx={{
                              m: 0,
                              p: 0,
                              '&> li': {
                                px: 0,
                                py: 0.625
                              }
                            }}
                            component="ul"
                          >
                            {plan.title === 'Basic'
                              ? planBasic.map((list, index) => (
                                  <Fragment key={index}>
                                    <ListItem>
                                      <ListItemText primary={list} sx={{ textAlign: 'center' }} />
                                    </ListItem>
                                  </Fragment>
                                ))
                              : plan.title === 'Standard'
                              ? planStandard.map((list, index) => (
                                  <Fragment key={index}>
                                    <ListItem>
                                      <ListItemText primary={list} sx={{ textAlign: 'center' }} />
                                    </ListItem>
                                  </Fragment>
                                ))
                              : plan.title === 'Premium'
                              ? planPremium.map((list, index) => (
                                  <Fragment key={index}>
                                    <ListItem>
                                      <ListItemText primary={list} sx={{ textAlign: 'center' }} />
                                    </ListItem>
                                  </Fragment>
                                ))
                              : planList.map((list, i) => (
                                  <Fragment key={i}>
                                    <ListItem sx={!plan.permission.includes(i) ? priceListDisable : {}}>
                                      <ListItemText primary={list} sx={{ textAlign: 'center' }} />
                                    </ListItem>
                                  </Fragment>
                                ))}
                          </List>
                        </Grid>
                      </Grid>
                    </MainCard>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Pricing;
