// // third-party
// import { FormattedMessage } from 'react-intl';

// // assets
// import { SiGoogleads } from 'react-icons/si';
// import { IoIosPeople } from 'react-icons/io';
// import { IoPersonAdd } from 'react-icons/io5';
// import { FaSearch } from 'react-icons/fa';

// // icons
// const icons = {
//   IoPersonAdd,
//   SiGoogleads,
//   IoIosPeople,
//   FaSearch
// };

// // ==============================|| MENU ITEMS - WIDGETS ||============================== //

// const competitors = {
//   id: 'group-widget',
//   title: <FormattedMessage id="competitors" />,
//   icon: icons.widgets,
//   type: 'group',
//   children: [
//     {
//       id: 'Competitor Analysis',
//       title: <FormattedMessage id="Competitor Analysis" />,
//       type: 'collapse',
//       icon: icons.authentication,
//       children: [
//         {
//           id: 'Ads transparency',
//           title: <FormattedMessage id="Ads transparency" />,
//           type: 'item',
//           url: '/dashboard/ads-transparency',
//           icon: icons.SiGoogleads
//         },
//         {
//           id: 'Competitors',
//           title: <FormattedMessage id="Competitors" />,
//           type: 'item',
//           url: '/dashboard/competitors',
//           icon: icons.IoIosPeople
//         },
//         {
//           id: 'add-Competitors',
//           title: <FormattedMessage id="add-Competitors" />,
//           type: 'item',
//           url: '/dashboard/add-competitors',
//           icon: icons.IoPersonAdd
//         },
//         {
//           id: 'Search',
//           title: <FormattedMessage id="Search" />,
//           type: 'item',
//           url: '/dashboard/Keyword',
//           icon: icons.FaSearch
//         }
//       ]
//     }
//   ]
// };

// export default competitors;


// import React from 'react';
import { SiGoogleads } from 'react-icons/si';
import { IoIosPeople } from 'react-icons/io';
import { IoPersonAdd } from 'react-icons/io5';
import { FaSearch } from 'react-icons/fa';

// icons
const icons = {
  IoPersonAdd,
  SiGoogleads,
  IoIosPeople,
  FaSearch
};

const createCompetitorsObject = (t) => ({
  id: 'group-widget',
  title: t('competitors'),
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'Competitor Analysis',
      title: t('Competitor_Analysis'),
      type: 'collapse',
      icon: icons.authentication,
      children: [
        {
          id: 'Ads transparency',
          title: t('Ads_transparency'),
          type: 'item',
          url: '/dashboard/ads-transparency',
          icon: icons.SiGoogleads
        },
        {
          id: 'Competitors',
          title: t('Competitors'),
          type: 'item',
          url: '/dashboard/competitors',
          icon: icons.IoIosPeople
        },
        {
          id: 'add-Competitors',
          title: t('add_Competitors'),
          type: 'item',
          url: '/dashboard/add-competitors',
          icon: icons.IoPersonAdd
        },
        {
          id: 'Search',
          title: t('Search'),
          type: 'item',
          url: '/dashboard/Keyword',
          icon: icons.FaSearch
        }
      ]
    }
  ]
});

export default createCompetitorsObject;
