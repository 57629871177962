/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
// project-imports
import MainCard from 'components/MainCard';
import { Avatar, Button, Card, CardHeader, Grid, Stack, TextField } from '@mui/material';
import { red } from '@mui/material/colors';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoadingButton from 'components/@extended/LoadingButton';
import { AdsContext } from 'components/contextApi/AdsContext';
import './Ads.css';
import Lottie from 'lottie-react';
import notFoundDark from 'assets/notFound.png';
import notFoundLight from 'assets/notFoundL.png';
import loading from 'assets/loading.json';
import { Eye } from 'iconsax-react';

// ==============================|| CUSTOMER - LIST ||============================== //

const AdsTransparency = () => {
  const theme = useTheme();
  const { GetAdsData, adsData, loadingData, tokenPage, allData, setAdsData } = useContext(AdsContext);
  const [domain, setDomain] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isValid, setIsValid] = useState(true);

  const handleChangeDomain = (event) => {
    const { value } = event.target;
    setDomain(value);
    setIsValid(validateDomain(value));
  };

  const validateDomain = (domain) => {
    // Regular expression to validate domain name
    const domainRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainRegex.test(domain);
  };

  const handleChangeStart = (value) => {
    const originalDate = new Date(value);

    // Extract year, month, and day
    const year = originalDate.getFullYear();
    // JavaScript months are zero-based, so add 1 to get the correct month
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');

    // Formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
  };
  const handleChangeEnd = (value) => {
    const originalDate = new Date(value);
    // Extract year, month, and day
    const year = originalDate.getFullYear();
    // JavaScript months are zero-based, so add 1 to get the correct month
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');
    // Formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;
    setEndDate(formattedDate);
  };

  return (
    <MainCard content={false}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setAdsData([]);
          GetAdsData({ start_date: startDate, end_date: endDate, domain });
        }}
      >
        <Stack spacing={1} alignItems="center" sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems="center">
            {/* Search TextField */}
            <Grid item xs={12} sm={4}>
              <TextField
                required
                placeholder="Search by Domain name"
                id="outlined-end-adornment"
                fullWidth
                onChange={handleChangeDomain}
                value={domain}
              />
              {!isValid && <p style={{ color: 'red' }}>Please enter a valid domain name</p>}
            </Grid>
            <Grid item xs={12} sm={8} container justifyContent="flex-end" alignItems={'center'} spacing={2}>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    required
                    label="Start Date"
                    onChange={(newValue) => handleChangeStart(newValue?.$d)}
                    renderInput={(props) => <TextField {...props} helperText="invalid mask" />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    required
                    label="End Date"
                    onChange={(newValue) => handleChangeEnd(newValue?.$d)}
                    renderInput={(props) => <TextField {...props} helperText="valid mask" />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <Button variant="contained" color="success" type="submit">
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {loadingData && adsData?.length === 0 ? (
            <Lottie animationData={loading} loop={true} />
          ) : allData && allData.length === 0 && adsData?.length === 0 ? (
            <img src={theme.palette.mode === 'light' ? notFoundLight : notFoundDark} alt="not found" style={{ maxWidth: '100%' }} />
          ) : // <Lottie animationData={notFound} loop={true} />
          adsData?.length > 0 ? (
            <>
              <Grid container spacing={2}>
                {adsData?.map((item, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {item?.company_name.charAt(0)}
                          </Avatar>
                        }
                        title={item?.company_name}
                        subheader="Verified"
                      />
                      <div className="adImage" dangerouslySetInnerHTML={{ __html: item?.ad_image }} />
                      <div className="adInfo" style={{ padding: '0 15px' }}>
                        {item.info.map((it, i) => (
                          <div key={i}>
                            <h5>country: {it?.countries}</h5>
                            <h5>last_seen: {it?.last_seen}</h5>
                          </div>
                        ))}
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <LoadingButton
                loading={loadingData}
                variant="contained"
                loadingPosition="start"
                startIcon={<Eye />}
                disabled={loadingData}
                onClick={() => GetAdsData({ start_date: startDate, end_date: endDate, domain, token_page: tokenPage })}
              >
                {loadingData ? 'Loading...' : 'See More'}
              </LoadingButton>
            </>
          ) : (
            ''
          )}
        </Stack>
      </form>
      {/* add customer dialog */}
    </MainCard>
  );
};

AdsTransparency.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  fatherName: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default AdsTransparency;
