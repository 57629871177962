// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project-imports
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import cartReducer from './cart';
import kanban from './kanban';
import invoice from './invoice';
import settingsReducer from 'components/rtk/reducers/settingsSlice';
import whitelistReducer from 'components/rtk/reducers/whitelistSlice';
import blockIpReducer from 'components/rtk/reducers/blockIpSlice';
import clickReducer from 'components/rtk/reducers/clickSlice';
import campaignReducer from 'components/rtk/reducers/campaignSlice';
import accountReducer from 'components/rtk/reducers/accountSlice';
import pagesReducer from 'components/rtk/reducers/pagesSlice';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  kanban,
  invoice,
  settings: settingsReducer,
  whitelist: whitelistReducer,
  blockIp: blockIpReducer,
  click: clickReducer,
  campaign: campaignReducer,
  account: accountReducer,
  pages: pagesReducer
});

export default reducers;
