/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { createContext } from 'react';
import { notify } from 'utils/notify';
import { getRequest, postRequest } from 'utils/fetchMethods';

export const AdsContext = createContext();

// eslint-disable-next-line react/prop-types
export const AdsProvider = ({ children }) => {
  const [competitor, setCompetitor] = useState();
  const [countries, setCountries] = useState();
  const [allData, setAlldata] = useState();
  const [adsData, setAdsData] = useState([]);
  //   token_page
  const [tokenPage, setTokenPage] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const GetAdsData = async (params) => {
    try {
      setLoadingData(true);
      const res = await getRequest(params, `/users/transparency/searchAds`);
      if (!res) {
        setLoadingData(false);
        throw new Error(res?.msg);
      }
      if (res) {
        setLoadingData(false);
      }
      if (res?.data) {
        setAlldata(res?.data);
        // eslint-disable-next-line no-unsafe-optional-chaining
        setAdsData((prevAdsData) => [...prevAdsData, ...res?.data]);
      }

      //   setAdsData(res?.data);
      setTokenPage(res?.token_page);
    } catch (error) {
      setAdsData([]);
      setLoadingData(false);
      notify(error.message, 'error');
    }
  };

  const GetCompetitors = async (params) => {
    try {
      const res = await getRequest(params, `/users/competitor`);
      if (!res) {
        throw new Error(res?.msg);
      }
      if (res?.data) {
        setCompetitor(res?.data);
        // eslint-disable-next-line no-unsafe-optional-chaining
      }

      //   setAdsData(res?.data);
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const getCountries = async (params) => {
    try {
      const res = await getRequest(params, `/CountriesGoogleApi`);
      if (!res) {
        throw new Error(res?.msg);
      }
      if (res?.data) {
        setCountries(res?.data);
        // eslint-disable-next-line no-unsafe-optional-chaining
      }
      //   setAdsData(res?.data);
    } catch (error) {
      notify(error.message, 'error');
    }
  };
  const postCompetitors = async (body) => {
    try {
      const res = await postRequest(body, `/users/competitor/set`);
      if (!res) {
        throw new Error(res?.msg);
      }
      notify('Competitor Added Successfully', 'success');
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const [searchCountries, setSearchCountries] = useState([]);
  const getSearchCountries = async (params) => {
    try {
      const res = await getRequest(params, `/criteria-google`);
      if (!res) {
        throw new Error(res?.msg);
      }
      if (res) {
        const transformedData = res?.map((country) => ({
          value: country.canonical_name,
          label: country.canonical_name
        }));
        setSearchCountries(transformedData);
        // eslint-disable-next-line no-unsafe-optional-chaining
      }
    } catch (error) {
      notify(error.message, 'error');
    }
  };
  const [searchCompatitors, setSearchCompatitors] = useState([]);
  const [allDatasearch, setAlldatasearch] = useState();
  const [adsDatasearch, setAdsDatasearch] = useState([]);
  //   token_page
  const [tokenPagesearch, setTokenPagesearch] = useState();
  const [loadingDatasearch, setLoadingDatasearch] = useState(false);
  const getSearchCompatitors = async (params) => {
    try {
      setLoadingDatasearch(true);

      const res = await getRequest(params, `/users/transparency/preview-diagnosis`);
      if (!res) {
        setLoadingDatasearch(false);

        throw new Error(res?.msg);
      }
      if (res) {
        setLoadingDatasearch(false);
        setSearchCompatitors(res);
        // eslint-disable-next-line no-unsafe-optional-chaining
      }
      if (res?.data) {
        setAlldatasearch(res?.data);
        // eslint-disable-next-line no-unsafe-optional-chaining
        setAdsDatasearch((prevAdsData) => [...prevAdsData, ...res?.data]);
      }

      //   setAdsData(res?.data);
      setTokenPagesearch(res?.href);
    } catch (error) {
      notify(error.message, 'error');
    }
  };
  const deleteCometitor = async (body) => {
    try {
      const res = await postRequest(body, `/users/competitor/destroy`);
      if (!res) {
        throw new Error(res?.message);
      }
      notify('Competitor deleted successfully', 'success');
    } catch (error) {
      notify('try again', 'error');
    }
  };
  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <AdsContext.Provider
      value={{
        GetAdsData,
        setAdsData,
        adsData,
        loadingData,
        tokenPage,
        allData,
        competitor,
        GetCompetitors,
        getCountries,
        countries,
        postCompetitors,
        getSearchCountries,
        searchCountries,
        setSearchCountries,
        searchCompatitors,
        setSearchCompatitors,
        getSearchCompatitors,

        allDatasearch,
        adsDatasearch,
        tokenPagesearch,
        loadingDatasearch,
        setAdsDatasearch,
        deleteCometitor
      }}
    >
      {children}
    </AdsContext.Provider>
  );
};
