// store/settingsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notify } from 'utils/notify';
import { getRequest, postRequest } from 'utils/fetchMethods';

export const getSettings = createAsyncThunk('settings/getSettings', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/users/settings/get`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

export const postSettings = createAsyncThunk('settings/postSettings', async (body, { rejectWithValue }) => {
  try {
    const res = await postRequest(body, `/users/settings/set`);
    if (!res) {
      throw new Error(res?.message);
    }
    notify('IP added successfully', 'success');
    return res;
  } catch (error) {
    notify('The IP field must be a valid IP address', 'error');
    return rejectWithValue(error.message);
  }
});

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    dataSettings: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.dataSettings = action.payload;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postSettings.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default settingsSlice.reducer;
