/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import './About.css';
import { Grid, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { PagesContext } from 'components/contextApi/PagesContext';
import { getPage } from 'components/rtk/reducers/pagesSlice';
import { ConfigContext } from 'contexts/ConfigContext';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

const About = ({ title }) => {
  const theme = useTheme();
  // const { GetPage, dataPages } = useContext(PagesContext);
  // useEffect(() => {
  //   GetPage({ id: 3 });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const dispatch = useDispatch();
  const { dataPages, loading, error } = useSelector((state) => state.pages);

  useEffect(() => {
    dispatch(getPage({ id: 3 }));
  }, []);

  const { ...config } = useContext(ConfigContext);
  // console.log(config.i18n);
  return (
    <>
      <Helmet>
        <title>
          {config.i18n === 'ar'
            ? dataPages?.meta_title_ar ?? 'Gardifi || about-us'
            : dataPages?.meta_title_en ?? 'Gardifi || about-us'}
        </title>
        <meta
          name="description"
          content={
            config.i18n === 'ar'
              ? dataPages?.meta_ar ??
                'Welcome to the Gardifi! This centralized hub empowers you to oversee and optimize various aspects of your online presence effortlessly. From managing accounts and campaigns to monitoring click activities and IP security, this dashboard offers a comprehensive suite of tools to streamline your operations. Stay informed, take control, and make data-driven decisions with ease, all from one convenient interface.'
              : dataPages?.meta_en ??
                'Welcome to the Gardifi! This centralized hub empowers you to oversee and optimize various aspects of your online presence effortlessly. From managing accounts and campaigns to monitoring click activities and IP security, this dashboard offers a comprehensive suite of tools to streamline your operations. Stay informed, take control, and make data-driven decisions with ease, all from one convenient interface.'
          }
          // content="Welcome to the Gardifi! This centralized hub empowers you to oversee and optimize various aspects of your online presence effortlessly. From managing accounts and campaigns to monitoring click activities and IP security, this dashboard offers a comprehensive suite of tools to streamline your operations. Stay informed, take control, and make data-driven decisions with ease, all from one convenient interface."
        />
      </Helmet>
      {dataPages && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
          className="common_about"
        >
          <Typography variant="h2" component="h2">
            <span>{dataPages && config.i18n === 'ar' ? dataPages?.name_ar : config.i18n === 'en' ? dataPages?.name_en : title}</span>
          </Typography>
          <Box
            container
            sx={{
              display: 'flex',
              margin: '80px 0 0',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              background: theme.palette.background.paper,
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
          >
            <Grid
              container
              spacing={0}
              justifyContent="center"
              sx={{
                width: '70%',
                padding: '10px 20px',
                '& > .MuiGrid-item': {
                  '& img': {
                    padding: 1.3
                  }
                }
              }}
            >
              <Grid container item xs={12}>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                {/* {dataPages && ( */}
                <>
                  {config.i18n === 'ar' && (
                    <div style={{ direction: 'rtl' }} dangerouslySetInnerHTML={{ __html: dataPages?.description_ar }} />
                  )}
                  {config.i18n === 'en' && <div dangerouslySetInnerHTML={{ __html: dataPages?.description_en }} />}
                </>
                {/* )} */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default About;
