/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputLabel, Stack, TextField, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
import IconButton from 'components/@extended/IconButton';
import { Trash } from 'iconsax-react';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';
import { AccountContext } from 'components/contextApi/GetAccounts';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts, postAccount } from 'components/rtk/reducers/accountSlice';

// constant
const getInitialValues = (account) => {
  const newAccount = {
    google_id: '',
    email: '',
    account_name: ''
  };

  if (account) {
    newAccount.name = account.fatherName;
    newAccount.location = account.address;
    return _.merge({}, newAccount, account);
  }

  return newAccount;
};

const allStatus = ['Complicated', 'Single', 'Relationship'];

// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const AddAccount = ({ account, onCancel }) => {
  const theme = useTheme();
  const isCreating = !account;

  const AccountSchema = Yup.object().shape({
    email: Yup.string().max(255).required('Email is required').email('Must be a valid email'),
    account_name: Yup.string().max(255).required('Account name is required')
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    onCancel();
  };

  // const { GetAccounts } = useContext(AccountContext);
  // useEffect(() => {
  //   GetAccounts();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const { postAccount } = useContext(AccountContext);

  const dispatch = useDispatch();
  const { dataAccounts, loading, error } = useSelector((state) => state.account);

  useEffect(() => {
    dispatch(getAccounts());
  }, []);

  const formik = useFormik({
    initialValues: getInitialValues(account),
    validationSchema: AccountSchema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        let dataSendApi = new FormData();
        dataSendApi.append('email', values.email);
        dataSendApi.append('name', values.account_name);
        dispatch(postAccount(dataSendApi)).then(() => {
          dispatch(getAccounts());
        });
        // postAccount(dataSendApi).then(() => {
        //   GetAccounts();
        // });
        const newAccount = {
          google_id: values.google_id,
          email: values.email,
          account_name: values.account_name
        };

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{account ? 'Edit Customer' : 'New Account'}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <TextField
                          fullWidth
                          id="email"
                          placeholder="Enter Email"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="account-name">Account Name</InputLabel>
                        <TextField
                          fullWidth
                          id="account-name"
                          placeholder="Enter Account Name"
                          {...getFieldProps('account_name')}
                          error={Boolean(touched.account_name && errors.account_name)}
                          helperText={touched.account_name && errors.account_name}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {!isCreating && (
                    <Tooltip title="Delete account" placement="top">
                      <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                        <Trash variant="Bold" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                      {account ? 'Edit' : 'Add'}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {!isCreating && <AlertCustomerDelete title={account.fatherName} open={openAlert} handleClose={handleAlertClose} />}
    </>
  );
};

AddAccount.propTypes = {
  account: PropTypes.any,
  onCancel: PropTypes.func
};

export default AddAccount;
