import PropTypes from 'prop-types';
import { createContext } from 'react';
import React from 'react';

// project-imports
import config from 'config';
import useLocalStorage from 'hooks/useLocalStorage';
// import { useLocation } from 'react-router-dom';
// import i18n from 'i18n';

// initial state
const initialState = {
  ...config,
  onChangeContainer: () => {},
  onChangeLocalization: () => {},
  onChangeMode: () => {},
  onChangePresetColor: () => {},
  onChangeDirection: () => {},
  onChangeMiniDrawer: () => {},
  onChangeMenuOrientation: () => {},
  onChangeMenuCaption: () => {},
  onChangeFontFamily: () => {},
  onChangeContrast: () => {}
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

function ConfigProvider({ children }) {
  const [config, setConfig] = useLocalStorage('able-pro-material-react-ts-config', initialState);

  // const location = useLocation();
  // const [lang, setLang] = useState(() => {
  //   const storedLang = config.lang;
  //   if (location.pathname.startsWith('/ar')) {
  //     return 'ar';
  //   } else if (location.pathname.startsWith('/en')) {
  //     return 'en';
  //   } else {
  //     return storedLang || 'ar'; // Default to Arabic if no language stored
  //   }
  // });

  const onChangeLocalization = (lang) => {
    setConfig({
      ...config,
      i18n: lang
    });
    // i18n.changeLanguage(lang);
  };

  // useEffect(() => {
  //   const pathname = location.pathname;
  //   if (pathname.startsWith('/ar') || pathname.startsWith('/en')) {
  //     const newLang = pathname.substring(1, 3);
  //     setLang(newLang);
  //     onChangeLocalization(lang);
  //   }
  // }, [location.pathname]);

  const onChangeContainer = () => {
    setConfig({
      ...config,
      container: !config.container
    });
  };

  const onChangeMode = (mode) => {
    setConfig({
      ...config,
      mode
    });
  };

  const onChangePresetColor = (theme) => {
    setConfig({
      ...config,
      presetColor: theme
    });
  };

  const onChangeDirection = (direction) => {
    setConfig({
      ...config,
      themeDirection: direction
    });
  };

  const onChangeMiniDrawer = (miniDrawer) => {
    setConfig({
      ...config,
      miniDrawer
    });
  };

  const onChangeContrast = () => {
    setConfig({
      ...config,
      themeContrast: !config.themeContrast
    });
  };

  const onChangeMenuCaption = () => {
    setConfig({
      ...config,
      menuCaption: !config.menuCaption
    });
  };

  const onChangeMenuOrientation = (layout) => {
    setConfig({
      ...config,
      menuOrientation: layout
    });
  };

  const onChangeFontFamily = (fontFamily) => {
    setConfig({
      ...config,
      fontFamily
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeContainer,
        onChangeLocalization,
        onChangeMode,
        onChangePresetColor,
        onChangeDirection,
        onChangeMiniDrawer,
        onChangeMenuOrientation,
        onChangeMenuCaption,
        onChangeFontFamily,
        onChangeContrast
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

ConfigProvider.propTypes = {
  children: PropTypes.node
};

export { ConfigProvider, ConfigContext };
