// // third-party
// import { FormattedMessage } from 'react-intl';

// // assets
// import { Story, Fatrows, PresentionChart } from 'iconsax-react';
// import { FaMouse } from 'react-icons/fa';
// import { MdCampaign } from 'react-icons/md';
// import { MdManageAccounts } from 'react-icons/md';
// import { MdBlock } from 'react-icons/md';
// import { FaListAlt } from 'react-icons/fa';
// import { MdOutlineSettingsSuggest } from 'react-icons/md';
// import { SiGoogleads } from 'react-icons/si';

// // icons
// const icons = {
//   widgets: Story,
//   statistics: Story,
//   data: Fatrows,
//   chart: PresentionChart,
//   click: FaMouse,
//   blockSettings: MdOutlineSettingsSuggest,
//   list: FaListAlt,
//   blockip: MdBlock,
//   accounts: MdManageAccounts,
//   campaign: MdCampaign,
//   SiGoogleads
// };

// // ==============================|| MENU ITEMS - WIDGETS ||============================== //

// const clicks = {
//   id: 'group-widget',
//   title: <FormattedMessage id="clicks" />,
//   icon: icons.widgets,
//   type: 'group',
//   children: [
//     {
//       id: 'accounts',
//       title: <FormattedMessage id="accounts" />,
//       type: 'item',
//       url: '/dashboard/accounts',
//       icon: icons.accounts
//     },
//     {
//       id: 'campaigns',
//       title: <FormattedMessage id="campaigns" />,
//       type: 'item',
//       url: '/dashboard/campaigns',
//       icon: icons.campaign
//     },
//     {
//       id: 'clicks',
//       title: <FormattedMessage id="clicks" />,
//       type: 'item',
//       url: '/dashboard/clicks',
//       icon: icons.click
//     },
//     {
//       id: 'blocked ip',
//       title: <FormattedMessage id="blocked ip" />,
//       type: 'item',
//       url: '/dashboard/BlockedIp',
//       icon: icons.blockip
//     },
//     {
//       id: 'ip whiteList',
//       title: <FormattedMessage id="ip whiteList" />,
//       type: 'item',
//       url: '/dashboard/IpWhiteList',
//       icon: icons.list
//     },
//     {
//       id: 'blocking settings',
//       title: <FormattedMessage id="blocking settings" />,
//       type: 'item',
//       url: '/dashboard/BlockingSettings',
//       icon: icons.blockSettings
//     }
//     // {
//     //   id: 'Ads transparency',
//     //   title: <FormattedMessage id="Ads transparency" />,
//     //   type: 'item',
//     //   url: '/dashboard/ads-transparency',
//     //   icon: icons.SiGoogleads
//     // }
//   ]
// };

// export default clicks;

// clicks.js
// clicks.js
import { Story, Fatrows, PresentionChart } from 'iconsax-react';
import { FaMouse } from 'react-icons/fa';
import { MdCampaign, MdManageAccounts, MdBlock, MdOutlineSettingsSuggest } from 'react-icons/md';
import { FaListAlt } from 'react-icons/fa';
import { SiGoogleads } from 'react-icons/si';

const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart,
  click: FaMouse,
  blockSettings: MdOutlineSettingsSuggest,
  list: FaListAlt,
  blockip: MdBlock,
  accounts: MdManageAccounts,
  campaign: MdCampaign,
  SiGoogleads
};

const createClicksObject = (t) => ({
  id: 'group-widget',
  title: t('Clicks'),
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'accounts',
      title: t('Accounts'),
      type: 'item',
      url: '/dashboard/accounts',
      icon: icons.accounts
    },
    {
      id: 'campaigns',
      title: t('Campaigns'),
      type: 'item',
      url: '/dashboard/campaigns',
      icon: icons.campaign
    },
    {
      id: 'clicks',
      title: t('Clicks'),
      type: 'item',
      url: '/dashboard/clicks',
      icon: icons.click
    },
    {
      id: 'blocked_ip',
      title: t('blocked_ip'),
      type: 'item',
      url: '/dashboard/BlockedIp',
      icon: icons.blockip
    },
    {
      id: 'ip_whitelist',
      title: t('ip_whitelist'),
      type: 'item',
      url: '/dashboard/IpWhiteList',
      icon: icons.list
    },
    {
      id: 'blocking_settings',
      title: t('blocking_settings'),
      type: 'item',
      url: '/dashboard/BlockingSettings',
      icon: icons.blockSettings
    }
    // {
    //   id: 'ads_transparency',
    //   title: t('ads_transparency'),
    //   type: 'item',
    //   url: '/dashboard/ads-transparency',
    //   icon: icons.SiGoogleads
    // }
  ]
});

export default createClicksObject;
