import PropTypes from 'prop-types';
// import { useState } from 'react';
// material-ui
import { Button, Grid, InputLabel, Stack, Typography, TextField, FormControl, Select, MenuItem } from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project-imports
import AnimateButton from 'components/@extended/AnimateButton';

const validationSchema = yup.object({
  Name: yup.string().required('First Name is required'),
  Phone: yup.number().required('Phone Number is required'),
  Manage: yup.string().required('Please select one'),
  industry: yup.string().required('Please select one'),
  Company: yup.string().required('Company name / Website is required'),
  Size: yup.string().required('Company size is required')
});

// ==============================|| VALIDATION WIZARD - ADDRESS  ||============================== //

const TellUs = ({ shippingData, setShippingData, handleNext, setErrorIndex }) => {
  const formik = useFormik({
    initialValues: {
      Name: shippingData.Name,
      Phone: shippingData.Phone,
      Manage: shippingData.Manage,
      industry: shippingData.industry,
      Company: shippingData.Company,
      Size: shippingData.Size
    },
    validationSchema,
    onSubmit: (values) => {
      setShippingData({
        Name: values.Name,
        Phone: values.Phone,
        Manage: values.Manage,
        industry: values.industry,
        Company: values.Company,
        Size: values.Size
      });
      handleNext();
    }
  });
  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Tell us about yourself and your business
      </Typography>
      <form onSubmit={formik.handleSubmit} id="validation-forms">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1}>
              <InputLabel>Name</InputLabel>
              <TextField
                id="Name"
                name="Name"
                placeholder="Name *"
                value={formik.values.Name}
                onChange={formik.handleChange}
                error={formik.touched.Name && Boolean(formik.errors.Name)}
                helperText={formik.touched.Name && formik.errors.Name}
                fullWidth
                autoComplete="given-name"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1}>
              <InputLabel>Phone Number</InputLabel>
              <TextField
                id="Phone"
                name="Phone"
                placeholder="Phone Number (including the country prefix) *"
                value={formik.values.Phone}
                onChange={formik.handleChange}
                error={formik.touched.Phone && Boolean(formik.errors.Phone)}
                helperText={formik.touched.Phone && formik.errors.Phone}
                fullWidth
                autoComplete="given-Phone"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Who do you manage ads for ?</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Who do you manage ads for ?"
                  //   id="demo-simple-select"
                  name="Manage"
                  id="Manage"
                  onChange={formik.handleChange}
                  value={formik.values.Manage}
                  error={formik.touched.Manage && Boolean(formik.errors.Manage)}
                  helperText={formik.touched.Manage && formik.errors.Manage}
                >
                  <MenuItem value={'For myself'}>For myself</MenuItem>
                  <MenuItem value={'For my clients'}>For my clients</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Your industry</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Your industry"
                  //   id="demo-simple-select"
                  name="industry"
                  id="industry"
                  onChange={formik.handleChange}
                  value={formik.values.industry}
                  error={formik.touched.industry && Boolean(formik.errors.industry)}
                  helperText={formik.touched.industry && formik.errors.industry}
                >
                  <MenuItem value={'Marketing & Advertising'}>Marketing & Advertising</MenuItem>
                  <MenuItem value={'Business & Information'}>Business & Information</MenuItem>
                  <MenuItem value={'Education'}>Education</MenuItem>
                  <MenuItem value={'Finance & Insurance'}>Finance & Insurance</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Stack spacing={1}>
              <InputLabel>Compane name / Website</InputLabel>
              <TextField
                id="Company"
                name="Company"
                placeholder="Company name / Website *"
                value={formik.values.Company}
                onChange={formik.handleChange}
                error={formik.touched.Company && Boolean(formik.errors.Company)}
                helperText={formik.touched.Company && formik.errors.Company}
                fullWidth
                autoComplete="given-Company"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Company size (number of employees)</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Company size (number of employees)"
                  //   id="demo-simple-select"
                  name="Size"
                  id="Size"
                  onChange={formik.handleChange}
                  value={formik.values.Size}
                  error={formik.touched.Size && Boolean(formik.errors.Size)}
                  helperText={formik.touched.Size && formik.errors.Size}
                >
                  <MenuItem value={'1-10'}>1-10</MenuItem>
                  <MenuItem value={'11-50'}>11-50</MenuItem>
                  <MenuItem value={'51-100'}>51-100</MenuItem>
                  <MenuItem value={'+100'}>+100</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button variant="contained" sx={{ my: 3, ml: 1 }} type="submit" onClick={() => setErrorIndex(0)}>
                  Next
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

TellUs.propTypes = {
  shippingData: PropTypes.object,
  setShippingData: PropTypes.func,
  handleNext: PropTypes.func,
  setErrorIndex: PropTypes.func
};

export default TellUs;
