/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

// material-ui
import { Button, Grid, InputLabel, Stack, Typography, TextField, List, ListItem } from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project-imports
import AnimateButton from 'components/@extended/AnimateButton';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

// const validationSchema = yup.object({
//   AccountId: yup.string().required('Account ID is required')
// });

// ==============================|| VALIDATION WIZARD - ADDRESS  ||============================== //

const Approve = ({ shippingData, setShippingData, handleNext, setErrorIndex }) => {
  const formik = useFormik({
    // initialValues: {
    //   AccountId: shippingData.AccountId
    // },
    // validationSchema,
    onSubmit: () => {
      //   setShippingData({
      //     AccountId: values.AccountId
      //   });
      handleNext();
    }
  });

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Approve the Google Ads link request
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
        In order to monitor and protect your ads, we need to link your Google <br />
        Ads account to our manager account. it will enable us to track your ad <br />
        performance, analyze the data and block fraudulent activities.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
        We just sent a link request to your {'111-111-1111'} Google Ads Account.
        <br />
        Please approve the request by following the instructions below:
      </Typography>
      <ol style={{ marginBottom: '20px' }}>
        <li>Sign in to your Google Ads account </li>
        <li>In the upper right corner, click the SEARCH icon, then type {'"Access and security"'} and enter the page</li>
        <li>Switch to the Managers Tab</li>
        <li>Approve the link request from Gardifi.com</li>
      </ol>
      <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
        Once you approve our request in your Google Ads Account, click the button next.
      </Typography>
      {/* <form onSubmit={formik.handleSubmit} id="validation-forms"> */}
      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <AnimateButton>
              <Button variant="contained" sx={{ my: 3, ml: 1 }} type="submit" onClick={() => handleNext()}>
                Next
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid> */}
      {/* </form> */}
    </>
  );
};

Approve.propTypes = {
  shippingData: PropTypes.object,
  setShippingData: PropTypes.func,
  handleNext: PropTypes.func,
  setErrorIndex: PropTypes.func
};

export default Approve;
