/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router';

// material-ui
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

// third-party
import * as yup from 'yup';
import { v4 as UIDV4 } from 'uuid';
import { FieldArray, Form, Formik } from 'formik';

// project-imports
import MainCard from 'components/MainCard';
import InvoiceItem from './compItems/AddCompItem';

// assets
import { Add } from 'iconsax-react';
import { useContext, useEffect } from 'react';
import { AdsContext } from 'components/contextApi/AdsContext';

const validationSchema = yup.object({
  url: yup.string().required('Url is required'),
  invoice_detail: yup
    .array()
    .required('Invoice details is required')
    .of(
      yup.object().shape({
        start_date: yup.string().required('start_date is required'),
        end_date: yup.string().required('end_date is required'),
        country_id: yup.string().required('country is required')
      })
    )
    .min(1, 'Invoice must have at least 1 items')
});

// ==============================|| INVOICE - CREATE ||============================== //

const Add_Competitors = () => {
  const navigation = useNavigate();

  const { getCountries } = useContext(AdsContext);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { postCompetitors } = useContext(AdsContext);
  const handlerCreate = (values) => {
    postCompetitors({
      type: values.type,
      value: values.url,
      dates: values.invoice_detail
    });
    // const NewList = {
    //   //   type: values.type,
    //   url: values.url,
    //   invoice_detail: values.invoice_detail
    // };

    // dispatch(getInvoiceList()).then(() => {
    //   dispatch(getInvoiceInsert(NewList)).then(() => {
    //     dispatch(
    //       openSnackbar({
    //         open: true,
    //         message: 'Competitor Added successfully',
    //         anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //         variant: 'alert',
    //         alert: {
    //           color: 'success'
    //         },
    //         close: false
    //       })
    //     );
    //     navigation('/dashboard/competitors');
    //   });
    // });
  };

  return (
    <MainCard>
      <Formik
        initialValues={{
          type: 1,
          url: '',
          invoice_detail: [
            {
              id: UIDV4(),
              start_date: null,
              end_date: null,
              country_id: ''
            }
          ]
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handlerCreate(values);
        }}
      >
        {({ handleBlur, errors, handleChange, handleSubmit, values, touched, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Stack spacing={1}>
                    <InputLabel>Domain</InputLabel>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        type="text"
                        placeholder="Domain"
                        name="url"
                        id="url"
                        value={values.url}
                        onChange={handleChange}
                        error={Boolean(errors.url && touched.url)}
                      />
                    </FormControl>
                  </Stack>
                  {touched.url && errors.url && <FormHelperText error={true}>{errors.url}</FormHelperText>}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Detail</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    name="invoice_detail"
                    render={({ remove, push }) => {
                      return (
                        <>
                          <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>#</TableCell>
                                  <TableCell>Start Date</TableCell>
                                  <TableCell>End Date</TableCell>
                                  <TableCell>Country</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.invoice_detail?.map((item, index) => (
                                  <TableRow key={item.id}>
                                    <TableCell>{values.invoice_detail.indexOf(item) + 1}</TableCell>
                                    <InvoiceItem
                                      key={item.id}
                                      id={item.id}
                                      index={index}
                                      start_date={item.start_date}
                                      end_date={item.end_date}
                                      country_id={item.country_id}
                                      onDeleteItem={(index) => remove(index)}
                                      onEditItem={handleChange}
                                      Blur={handleBlur}
                                      errors={errors}
                                      touched={touched}
                                      setFieldValue={setFieldValue}
                                    />
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Divider />
                          {touched.invoice_detail && errors.invoice_detail && !Array.isArray(errors?.invoice_detail) && (
                            <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
                              <FormHelperText error={true}>{errors.invoice_detail}</FormHelperText>
                            </Stack>
                          )}
                          <Grid container justifyContent="space-between">
                            <Grid item xs={12} md={8}>
                              <Box sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 0 }}>
                                <Button
                                  color="primary"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    push({
                                      id: UIDV4(),
                                      start_date: null,
                                      end_date: null,
                                      country_id: ''
                                    })
                                  }
                                  variant="dashed"
                                  sx={{ bgcolor: 'transparent !important' }}
                                >
                                  Add Item
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2} sx={{ height: '100%' }}>
                    <Button color="primary" variant="contained" type="submit">
                      Submit
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </MainCard>
  );
};

export default Add_Competitors;
