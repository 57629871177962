// store/clickSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notify } from 'utils/notify';
import { getRequest } from 'utils/fetchMethods';

// Async thunk for fetching clicks
export const getClicks = createAsyncThunk('click/getClicks', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/users/clicks`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

// Async thunk for fetching click details
export const getClickDetails = createAsyncThunk('click/getClickDetails', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/users/clicks/campaign-id`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

// Slice to manage click state
const clickSlice = createSlice({
  name: 'click',
  initialState: {
    dataClicks: null,
    clickDetails: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClicks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getClicks.fulfilled, (state, action) => {
        state.loading = false;
        state.dataClicks = action.payload;
      })
      .addCase(getClicks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getClickDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getClickDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.clickDetails = action.payload;
      })
      .addCase(getClickDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default clickSlice.reducer;
