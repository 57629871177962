/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Button, Dialog, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';

// third-party
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
  CSVExport,
  HeaderSort,
  IndeterminateCheckbox,
  //   SortingSelect,
  TablePagination,
  TableRowSelection
} from 'components/third-party/ReactTable';

import AddCustomer from 'sections/apps/customer/AddCustomer';
import CustomerView from './DetailsView';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';

// assets
import { Add, Edit, Eye, Trash, Wallet3 } from 'iconsax-react';
import { ThemeMode } from 'config';
// import AddCompetitors from './AddCompetitors';
import { AdsContext } from 'components/contextApi/AdsContext';
import { useNavigate } from 'react-router';
import { useSticky } from 'react-table-sticky';
import DeleteCompetitorModal from './DeleteCompetitorModal';

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      maxWidth: 400
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    // allColumns,
    visibleColumns,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
    preGlobalFilteredRows,
    setGlobalFilter,
    // setSortBy,
    selectedFlatRows
  } = useTable(
    {
      defaultColumn,

      columns,
      data,

      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 50
        // hiddenColumns: ['avatar', 'email']
        //   , sortBy: [sortBy]
      }
    },
    useSticky,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (matchDownSM) {
      setHiddenColumns(['age', 'contact', 'visits', 'email', 'status', 'avatar']);
    } else {
      setHiddenColumns(['avatar', 'email']);
    }
    // eslint-disable-next-line
  }, [matchDownSM]);

  const TableWrapper = styled('div')(() => ({
    '.header': {
      position: 'sticky',
      zIndex: 1,
      width: 'fit-content'
    },
    '& th[data-sticky-td]': {
      position: 'sticky',
      zIndex: '5 !important'
    }
  }));

  return (
    <>
      <TableRowSelection selected={Object?.keys(selectedRowIds)?.length} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
            {/* <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} /> */}
            {/* <Button variant="contained" startIcon={<Add />} onClick={handleAdd} size="small">
              Add Competitors
            </Button> */}
            <CSVExport
              data={selectedFlatRows?.length > 0 ? selectedFlatRows?.map((d) => d?.original) : data}
              filename={'customer-list.csv'}
            />
          </Stack>
        </Stack>
        <TableWrapper>
          <Table {...getTableProps()} stickyHeader>
            <TableHead>
              {headerGroups?.map((headerGroup) => (
                <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                  {headerGroup.headers?.map((column) => (
                    <TableCell
                      key={column}
                      {...column.getHeaderProps([{ className: column?.className }])}
                      sx={{ position: 'sticky !important' }}
                    >
                      <HeaderSort column={column} sort />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page?.map((row, i) => {
                prepareRow(row);
                const rowProps = row?.getRowProps();

                return (
                  <Fragment key={i}>
                    <TableRow
                      {...row?.getRowProps()}
                      onClick={() => {
                        row?.toggleRowSelected();
                      }}
                      sx={{ cursor: 'pointer', bgcolor: row?.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                    >
                      {row?.cells?.map((cell) => (
                        <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                  </Fragment>
                );
              })}
              <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                  <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| CUSTOMER - LIST ||============================== //

const Competitors = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  //   const data = useMemo(() => makeData(200), []);
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerDeleteId, setCustomerDeleteId] = useState('');
  const [add, setAdd] = useState(false);

  const { competitor, GetCompetitors } = useContext(AdsContext);
  useEffect(() => {
    GetCompetitors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDeleteId]);
  const handleAdd = () => {
    setAdd(!add);
    if (customer && !add) setCustomer(null);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const data = competitor;
  const navigation = useNavigate();
  const columns = useMemo(
    () => [
      {
        title: 'Row Selection',
        Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'selection',
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        disableSortBy: true
      },
      {
        Header: 'id',
        accessor: 'id'
      },
      {
        Header: 'user id',
        accessor: 'user_id'
      },
      {
        Header: 'Url',
        accessor: 'name',
        Cell: ({ row }) => row?.original?.value
      },
      {
        Header: 'Dates & Countries',
        className: 'cell-center',
        disableSortBy: true,
        Cell: ({ row }) => {
          const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
          return (
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="View"
              >
                <IconButton
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    row.toggleRowExpanded();
                  }}
                >
                  {collapseIcon}
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      },
      {
        Header: 'Actions',
        className: 'cell-center',
        disableSortBy: true,
        Cell: ({ row }) => {
          const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
          return (
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
              <Tooltip title="view details">
                <Button
                  variant="contained"
                  color="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigation(`/dashboard/competitor/${row.values.id}`);
                  }}
                >
                  view
                </Button>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                    setCustomerDeleteId(row.original.id);
                  }}
                >
                  <Trash />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );

  const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={data[Number(row.id)]} />, [data]);
  return (
    <MainCard content={false}>
      <ScrollX sx={{ height: '90vh' }}>
        {data && <ReactTable columns={columns} data={data} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />}
      </ScrollX>
      <DeleteCompetitorModal title={customerDeleteId} open={open} handleClose={handleClose} />

      {/* <AlertCustomerDelete title={customerDeleteId} open={open} handleClose={handleClose} /> */}
    </MainCard>
  );
};

Competitors.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  fatherName: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default Competitors;
