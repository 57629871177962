// store/campaignSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notify } from 'utils/notify';
import { getRequest } from 'utils/fetchMethods';

// Async thunk for fetching campaigns
export const getCampaigns = createAsyncThunk('campaign/getCampaigns', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/users/campaigns`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

// Async thunk for fetching campaign details
export const getCampaignDetails = createAsyncThunk('campaign/getCampaignDetails', async (params, { rejectWithValue }) => {
  try {
    const res = await getRequest(params, `/users/campaigns/account-id`);
    if (!res) {
      throw new Error(res?.msg);
    }
    return res;
  } catch (error) {
    notify(error.message, 'error');
    return rejectWithValue(error.message);
  }
});

// Slice to manage campaign state
const campaignSlice = createSlice({
  name: 'campaign',
  initialState: {
    dataCampaigns: null,
    campaignDetails: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCampaigns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        state.dataCampaigns = action.payload;
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCampaignDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCampaignDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignDetails = action.payload;
      })
      .addCase(getCampaignDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default campaignSlice.reducer;
