/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Grid, List, ListItem, Stack, TableCell, TableRow, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets

// ==============================|| CUSTOMER - VIEW ||============================== //

const DetailsView = ({ data }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  //   console.log(data);
  return (
    <TableRow sx={{ '&:hover': { bgcolor: `transparent !important` }, overflow: 'hidden' }}>
      <TableCell colSpan={8} sx={{ p: 2.5, overflow: 'hidden' }}>
        <Transitions type="slide" direction="down" in={true}>
          <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }}>
            <Grid item xs={12} sm={7} md={8} lg={8} xl={9}>
              <Stack spacing={2.5}>
                <MainCard title="More Details">
                  <List sx={{ py: 0 }}>
                    {data && data?.dates_countries?.length > 0
                      ? data?.dates_countries.map((item) => (
                          <ListItem divider={!matchDownMD} key={item?.id}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={4}>
                                <Stack spacing={0.5}>
                                  <Typography color="secondary">Start Date</Typography>
                                  <Typography>{item.start_date ?? ''}</Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Stack spacing={0.5}>
                                  <Typography color="secondary">End Date</Typography>
                                  <Typography>{item?.end_date ?? ''}</Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Stack spacing={0.5}>
                                  <Typography color="secondary">Country</Typography>
                                  <Typography>{item?.country?.name ?? ''}</Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                          </ListItem>
                        ))
                      : 'no data'}
                  </List>
                </MainCard>
              </Stack>
            </Grid>
          </Grid>
        </Transitions>
      </TableCell>
    </TableRow>
  );
};

DetailsView.propTypes = {
  data: PropTypes.object
};

export default DetailsView;
