import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// fonts
import 'assets/fonts/inter/inter.css';

// scroll bar
import 'simplebar/dist/simplebar.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

// project-imports
import App from './App';
import { store, persister } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'components/contextApi/RegisterationContext';
import { HelmetProvider } from 'react-helmet-async';
// import { PagesProvider } from 'components/contextApi/PagesContext';
// import { AccountProvider } from 'components/contextApi/GetAccounts';
// import { CampaignProvider } from 'components/contextApi/GetCampaigns';
// import { ClickProvider } from 'components/contextApi/GetClicks';
// import { BlockIpProvider } from 'components/contextApi/BlockIp';
// import { WhitelistProvider } from 'components/contextApi/WhitelistContext';
// import { SettingsProvider } from 'components/contextApi/BlockingSettingsContext';
import { EditorProvider } from 'components/contextApi/EditorContext';
import { AdsProvider } from 'components/contextApi/AdsContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// eslint-disable-next-line no-unused-vars
import i18n from './i18n'; // Import the i18n instance
// import { store as appStore } from 'components/rtk/store';

const container = document.getElementById('root');
const root = createRoot(container);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <ConfigProvider>
          <AuthProvider>
            <EditorProvider>
              <AdsProvider>
                <HelmetProvider>
                  <App />
                </HelmetProvider>
              </AdsProvider>
            </EditorProvider>
          </AuthProvider>
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
