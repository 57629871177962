/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import AnimateButton from 'components/@extended/AnimateButton';
import React, { useState } from 'react';
// material-ui
import { Button, Step, Stepper, StepLabel, Stack, Typography } from '@mui/material';

// project-imports

import MainCard from 'components/MainCard';
import AddressForm from 'sections/forms/wizard/validation-wizard/AddressForm';
// import PaymentForm from 'sections/forms/wizard/validation-wizard/PaymentForm';
import Review from 'sections/forms/wizard/validation-wizard/Review';
import TellUs from 'sections/forms/wizard/validation-wizard/TellUs';
import ConnectAccount from 'sections/forms/wizard/validation-wizard/ConnectAccount';
import Approve from 'sections/forms/wizard/validation-wizard/Approve';
import { Navigate, useNavigate } from 'react-router';

const steps = ['Step 1', 'Step 2', 'Step 3'];

const getStepContent = (step, handleNext, handleBack, setErrorIndex, shippingData, setShippingData, paymentData, setPaymentData) => {
  switch (step) {
    case 0:
      return <TellUs handleNext={handleNext} setErrorIndex={setErrorIndex} shippingData={shippingData} setShippingData={setShippingData} />;
    case 1:
      return (
        <ConnectAccount
          handleNext={handleNext}
          setErrorIndex={setErrorIndex}
          shippingData={shippingData}
          setShippingData={setShippingData}
        />
      );
    case 2:
      return (
        <Approve handleNext={handleNext} setErrorIndex={setErrorIndex} shippingData={shippingData} setShippingData={setShippingData} />
      );
    // case 2:
    //   return (
    //     <AddressForm handleNext={handleNext} setErrorIndex={setErrorIndex} shippingData={shippingData} setShippingData={setShippingData} />
    //   );
    // case 3:
    //   return (
    //     <PaymentForm
    //       handleNext={handleNext}
    //       handleBack={handleBack}
    //       setErrorIndex={setErrorIndex}
    //       paymentData={paymentData}
    //       setPaymentData={setPaymentData}
    //     />
    //   );
    // case 3:
    //   return <Review />;
    default:
      throw new Error('Unknown step');
  }
};

const CompleteInfo = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [shippingData, setShippingData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [errorIndex, setErrorIndex] = useState(null);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setErrorIndex(null);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const navigate = useNavigate();
  return (
    <Grid container spacing={2.5} justifyContent="center">
      <Grid item xs={12} md={6} lg={7}>
        <MainCard title="Validation">
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label, index) => {
              const labelProps = {};

              if (index === errorIndex) {
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    Error
                  </Typography>
                );

                labelProps.error = true;
              }

              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <>
            {activeStep === steps.length ? (
              <>
                <Typography variant="h2" gutterBottom>
                  Congratulations! Your Gardifi protections is activated
                </Typography>
                <Typography variant="subtitle1">
                  From nowon, your Google Ads account is carefully protected by our advanced algorithm. No actions are required on your
                  side. Keeping you safe from click fraud is on us!
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <AnimateButton>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setShippingData({});
                        setPaymentData({});
                        setActiveStep(0);
                      }}
                      sx={{ my: 3, ml: 1 }}
                    >
                      Reset
                    </Button>
                  </AnimateButton>
                  <AnimateButton>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        Navigate('/dashboard/default');
                      }}
                      sx={{ my: 3, ml: 1 }}
                    >
                      GO TO DASHBOARD
                    </Button>
                  </AnimateButton>
                </Stack>
              </>
            ) : (
              <>
                {getStepContent(
                  activeStep,
                  handleNext,
                  handleBack,
                  setErrorIndex,
                  shippingData,
                  setShippingData,
                  paymentData,
                  setPaymentData
                )}
                {activeStep === steps.length - 1 && (
                  <Stack direction="row" justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                        Back
                      </Button>
                    )}
                    <AnimateButton>
                      <Button variant="contained" onClick={handleNext} sx={{ my: 3, ml: 1 }}>
                        {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                      </Button>
                    </AnimateButton>
                  </Stack>
                )}
              </>
            )}
          </>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default CompleteInfo;
